import React from "react";
import { Col, Row, message, Divider, List, Typography, Descriptions, Card, Space, Button, Image, Form, Input } from "antd";

import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { HeaderTop } from "../../components/HeaderTop";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
let { path } = require('../../public/public.json');

const inputStyle = {
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'solid',
  borderColor: '#707070',
  borderWidth: '1px',
  borderRadius: 0,
  fontSize: 18,
};

const Register = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const handleLoginOnClick = () => navigate(`/Login`);
  const onFinish = (values) => {
    let { name, username, password, repeatPassword } = values;

    if (password != repeatPassword) {
      error("密碼和確認密碼不正確");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("username", username);
    formData.append("password", password);
  
    axios.post(`${path}/register.php`, formData).then(res => {
      console.log(res)
      let {data, status} = res;
      if (status == 200 && data == "successful") {
        success();
        navigate('/');
      }
      error();
    });
  };
  const error = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: `註冊成功`,
    });
  };
  
  return (
    <>
      <div>
        <HeaderTop page={"Register"}/>
        <Row>
          <Col span={24}>
            <img alt="example" style={{ maxHeight: 274, width: "100%" }} src={require('../../assets/icons/login-logout-bg.jpg')} />
          </Col>
        </Row>

        <Row style={{ margin: 15 }}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                borderRadius: 10,
                marginTop: -130
              }}
            >
              <Row style={{ margin: -10 }}>
                <Col span={24}>
                  <p style={{ textAlign: "center", fontSize: 28, fontFamily: "Arial", fontWeight: "bold", paddingTop: 40 }}>註冊 </p>
                </Col>
                <Col span={24}>
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      marginLeft: 25, marginRight: 25
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label=""
                      className="username"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input placeholder="名稱" style={inputStyle} />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="username"

                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input placeholder="電話號碼" style={inputStyle} />
                    </Form.Item>

                    <Form.Item
                      label=""
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: '密碼',
                        },
                      ]}
                    >
                      <Input.Password placeholder="密碼" style={inputStyle} />
                    </Form.Item>

                    <Form.Item
                      label=""
                      name="repeatPassword"

                      rules={[
                        {
                          required: true,
                          message: '密碼',
                        },
                      ]}
                    >
                      <Input.Password placeholder="確認密碼" style={inputStyle} />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 50
                      }}
                    >
                      <Button type="primary" htmlType="submit" style={{ backgroundColor: "#016D49" }} size={"large"}>
                        <span style={{ color: "#FFFFFF", paddingLeft: 30, paddingRight: 30 }}>註冊</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24}>
                  <p style={{ textAlign: "center", fontSize: 16, paddingBottom: 20, paddingTop: 25 }}>已經有會員帳號？現在 <span style={{ fontSize: 18, color: "#016D49" }} onClick={() => handleLoginOnClick()}>登入</span> 享受獨家優惠</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Register;
