import React, { useState, useEffect } from 'react';
// import { LeftOutlined } from "@ant-design/icons";
import {
    Col,
    Row,
    Checkbox,
    Divider,
    List,
    Typography,
    Descriptions,
    Card,
    Space,
    Button,
    Image,
    Avatar,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import axios from 'axios';
import { Header1 } from '../../components/Header1';
import { Footer1 } from '../../components/Footer1';
import { HeaderTop } from '../../components/HeaderTop';
let { path } = require('../../public/public.json');

const data = [
    'Racing car sprays burning fuel into crowd.',
    'Japanese princess to wed commoner.',
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
    'Los Angeles battles huge wildfires.',
];
// const onChange = (value) => {
//   console.log("changed", value);
// };

const BookingRecord = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchData();
    }, []);

    const user = JSON.parse(sessionStorage.getItem('loginStatus'));

    const fetchData = async () => {
        // let user = JSON.parse(sessionStorage.getItem('loginStatus'));
        await axios
            .get(`${path}/score.php` + '?uid=' + user.uid)
            .then(res => {
                setData(res.data);
            });

    };


    function formatDate(dateString) {
        // 将日期字符串转换为Date对象
        const date = new Date(dateString);
    
        // 获取日期的各个部分
        const day = date.getDate();
        const month = date.getMonth() + 1; // 注意月份是从0开始的，所以要加1
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        // 根据需要添加零填充
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
        // 返回格式化后的日期字符串
        return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
    }
    

    // console.log(
    //     user
    // );
    // 修改这里添加头像等上部分
    // const fetchData = async () => {
    //     const formData = new FormData();
    //     let user = JSON.parse(sessionStorage.getItem('loginStatus'));
    //     formData.append('user_id', user.uid);

    //     await axios
    //         .post(`${path}/getUserOrderRecord.php`, formData)
    //         .then(res => {
    //             // console.log(res.data);
    //         });
    // };
    // 这里被我注释了
    // const score = [];


    // console.log(data[2]);

    // console.log(score);
    return (

        <>
            <HeaderTop
                // data={data}
                page={'BookingRecord'} />
            <Row style={{ margin: 15 }}>
                <Col span={24}>
                    <Card
                        style={{
                            width: '100%',
                            borderRadius: 10,
                        }}>
                        <Row style={{ margin: -10 }}>
                            <Col span={12}>
                                <Avatar
                                    size={120}
                                    src={require('../../assets/icons/account-icon.png')}
                                />
                            </Col>
                            <Col span={12}>
                                <Row style={{ marginTop: 30 }}>
                                    <Col
                                        span={24}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                        }}>
                                        會員名稱 : {user.name}
                                    </Col>
                                    <Col
                                        span={24}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                            paddingTop: 10,
                                            color: '#6A6A6A',
                                        }}>
                                        積分{' '}
                                        <span style={{ color: 'green' }}>
                                            {user.score}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <div>
                {data.map(person => <p key={person.uid}>

                    <Row

                        style={{ margin: 15 }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    width: '100%',
                                }}>
                                <Row>
                                    <Col
                                        span={24}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            color: '#0E0E0E',
                                            fontSize: 16,
                                        }}
                                    >
                                        日期 : {formatDate(person.addDate)}
                                    </Col>
                                    <Col
                                        span={24}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            color: '#0E0E0E',
                                            fontSize: 16,
                                        }}>
                                        單號 : IN000{person.orderId}
                                        {/* 这里是订单编号 */}
                                    </Col>
                                    <Col span={24}>
                                        <Row style={{ marginTop: 6 }} gutter={[8, 8]}>
                                            <Col
                                                span={16}
                                                style={{
                                                    fontFamily: 'Segoe UI, Regular',
                                                    fontSize: 16,
                                                }}>
                                                結單 : {person.score}$
                                            </Col>


                                            <Col
                                                span={8}
                                                style={{
                                                    fontFamily: 'Segoe UI, Regular',
                                                    fontSize: 16,
                                                    color: person.score > 0 ? '#016D49' : 'red',
                                                }}>
                                                {person.score > 0 ? `獲得：${person.score}` : `扣減：${person.score}`}
                                            </Col>
                                            {/* 这里判断了红绿色以及获得减扣 */}

                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                </p>)}
            </div>




            {/* <Row style={{margin: 15}}>
                <Col span={24}>
                    <Card
                        style={{
                            width: '100%',
                        }}>
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    fontFamily: 'Segoe UI, Regular',
                                    color: '#0E0E0E',
                                    fontSize: 16,
                                }}>
                                日期 : 28/02/2023 14:52
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    fontFamily: 'Segoe UI, Regular',
                                    color: '#0E0E0E',
                                    fontSize: 16,
                                }}>
                                單號 : IN000202
                            </Col>
                            <Col span={24}>
                                <Row style={{marginTop: 6}} gutter={[8, 8]}>
                                    <Col
                                        span={16}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                        }}>
                                        結單 : $200
                                    </Col>
                                    <Col
                                        span={8}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                            color:'#016D49',
                                        }}>
                                        獲得：+200
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row style={{margin: 15}}>
                <Col span={24}>
                    <Card
                        style={{
                            width: '100%',
                        }}>
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    fontFamily: 'Segoe UI, Regular',
                                    color: '#0E0E0E',
                                    fontSize: 16,
                                }}>
                                日期 : 28/02/2023 14:52
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    fontFamily: 'Segoe UI, Regular',
                                    color: '#0E0E0E',
                                    fontSize: 16,
                                }}>
                                單號 : IN000202
                            </Col>
                            <Col span={24}>
                                <Row style={{marginTop: 6}} gutter={[8, 8]}>
                                    <Col
                                        span={16}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                        }}>
                                        禮券名稱：全場8折
                                    </Col>
                                    <Col
                                        span={8}
                                        style={{
                                            fontFamily: 'Segoe UI, Regular',
                                            fontSize: 16,
                                            color:'#DA5C97',
                                        }}>
                                        扣減：- 100
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */
            }
        </>
    );

};



export default BookingRecord;
