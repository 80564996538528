import React from "react";
import { Item, Button } from "semantic-ui-react";
// import { Col, Row } from "antd";

export const OtherInterest = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "scroll",
          width: "90vw",
          height: "100px",
        }}
      >
        <div
          style={{
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            boxShadow: "1px 2px 9px #707070",
            padding: "2%",
            borderRadius: "5px",
            marginRight: "5%",
            height: "auto",
          }}
        >
          <Item.Image size="tiny" src={require("../assets/images/food3.png")} />
          <Item.Content style={{ padding: "1%", width: "50%" }}>
            <p style={{fontSize: 10, fontWeight:"bold"}}>孜然雞翼</p>
            <Item.Meta>
              $18
              <Button
                circular
                icon="add"
                style={{
                  float: "right",
                  fontSize: 10,
                  // color: "#016D49",
                  // backgroundColor: "white",
                  // border: "rgb(1, 109, 73) 1px solid",
                }}
              />
            </Item.Meta>
          </Item.Content>
        </div>
        <div
          style={{
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            boxShadow: "1px 2px 9px #707070",
            padding: "2%",
            borderRadius: "5px",
            marginRight: "5%",
            height: "auto",
          }}
        >
          <Item.Image size="tiny" src={require("../assets/images/food3.png")} />
          <Item.Content style={{ padding: "1%", width: "50%" }}>
            <p style={{fontSize: 10, fontWeight:"bold"}}>孜然雞翼</p>
            <Item.Meta>
              $18
              <Button
                circular
                icon="add"
                style={{
                  float: "right",
                  fontSize: 10,
                  // color: "#016D49",
                  // backgroundColor: "white",
                  // border: "rgb(1, 109, 73) 1px solid",
                }}
              />
            </Item.Meta>
          </Item.Content>
        </div>
      </div>
    </>
  );
};
export default OtherInterest;
