// import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Header} from './components/Header';
import {Header1} from './components/Header1';
import {Footer} from './components/Footer';
import {Footer1} from './components/Footer1';
import {OtherInterest} from './components/OtherInterest';

import Home from './pages/Home';
import Code from './pages/Home/handleCode';
import Error from './pages/Home/Error';
// import Logout from "./pages/Logout";

import Cart from './pages/Cart';
import Edit from './pages/Edit';
import FoodDetails from './pages/FoodDetails';
import OrderSuccess from './pages/OrderSuccess';
import OrderHistory from './pages/OrderHistory';
import Account from './pages/Account';
import AccountInfo from './pages/AccountInfo';

import BookingRecord from './pages/BookingRecord';
import Coupon from './pages/Coupon';
import CouponInfo from './pages/CouponInfo';
import Login from './pages/Login';
import Register from './pages/Register';
import CouponUsgeList from './pages/CouponUsgeList';
import ResgiterPersonalInfo from './pages/ResgiterPersonalInfo';

import 'semantic-ui-css/semantic.min.css';

function App() {
    return (
        // change basename="XXX" XXX= localhost/XXX
        // <BrowserRouter basename="/dist"> //server need current file name as dist
        <BrowserRouter basename="/">
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="/404" element={<Error />} />
                <Route path="/code" element={<Code />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/Header1" element={<Header1 />} />
                <Route path="/Footer" element={<Footer />} />
                <Route path="/Footer1" element={<Footer1 />} />
                <Route path="/OtherInterest" element={<OtherInterest />} />

                <Route path="/Register" element={<Register />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Account" element={<Account />} />
                <Route path="/AccountInfo" element={<AccountInfo />} />
                <Route path="/CouponUsgeList" element={<CouponUsgeList />} />
                <Route path="/BookingRecord" element={<BookingRecord />} />
                <Route path="/Coupon" element={<Coupon />} />
                <Route path="/CouponInfo" element={<CouponInfo />} />
                <Route
                    path="/ResgiterPersonalInfo"
                    element={<ResgiterPersonalInfo />}
                />
                <Route path="/Cart" element={<Cart />} />
                <Route path="/Edit" element={<Edit />} />
                <Route path="/FoodDetails" element={<FoodDetails />} />
                <Route path="/OrderSuccess" element={<OrderSuccess />} />
                <Route path="/OrderHistory" element={<OrderHistory />} />
                <Route path="*" element={<h2>Page Not Found</h2>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
