import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Col, Row, Divider } from "antd";
import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { OtherInterest } from "../../components/OtherInterest";
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const Edit = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Header1 />
      <Row
        style={{
          width: "100vw",
          height: "85vh",
          padding: "14% 5% 0% 5%",
          overflowY: "scroll",
        }}
      >
        <Row
          style={{
            width: "100%",
            padding: "1% 5%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={8}>
            <h4 style={{ fontWeight: "bold", fontSize: 20, color: "#016D49" }}>
              已選項目
            </h4>
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <a href="/Cart" style={{ color: "black" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >

                <EditOutlined />
                <p
                  style={{
                    fontSize: 15,
                    textAlign: "right",
                  }}
                >
                  取消更改
                </p>
              </div>
            </a>
          </Col>
        </Row>
        <Divider style={{ margin: "1% 0% 5%" }} />
        <div
          style={{
            // boxShadow: "1px 2px 9px #707070",
            width: "100%",
            paddingTop: "10%",
            // padding: "5%",
            // borderRadius: "2%",
          }}
        >

          <Row style={{
            padding: "5%",
            borderRadius: "15px", boxShadow: "1px 2px 9px #707070", marginBottom: "5%"
          }}>
            <Col span={7}>
              <h1 style={{ fontWeight: "bold", fontSize: 18 }}>牛腩米線</h1>
              <p style={{ fontSize: 12 }}>米線</p>
              <p style={{ fontSize: 12 }}>凍檸茶</p>
            </Col>
            <Col
              span={5}
              style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
              x 1
            </Col>
            <Col span={6}>
              <h1
                style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
                $45
              </h1>
            </Col>
            <Col span={6}>
              <Modal closeIcon open={open} trigger={<Button circular icon="x" style={{
                float: "right",
                fontSize: 10,
              }}
              />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
              >
                <Header icon='archive' content='確定刪除？' />
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> 取消
                  </Button>
                  <Button color='green' onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> 確定
                  </Button>
                </Modal.Actions>
              </Modal>


            </Col>
          </Row>
          <Row style={{
            padding: "5%",
            borderRadius: "15px", boxShadow: "1px 2px 9px #707070", marginBottom: "5%"
          }}>
            <Col span={7}>
              <h1 style={{ fontWeight: "bold", fontSize: 18 }}>牛腩米線</h1>
              <p style={{ fontSize: 12 }}>米線</p>
              <p style={{ fontSize: 12 }}>凍檸茶</p>
            </Col>
            <Col
              span={5}
              style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
              x 1
            </Col>
            <Col span={6}>
              <h1
                style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
                $45
              </h1>
            </Col>
            <Col span={6}>
              <Modal closeIcon open={open} trigger={<Button circular icon="x" style={{
                float: "right",
                fontSize: 10,
              }}
              />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
              >
                <Header icon='archive' content='確定刪除？' />
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> 取消
                  </Button>
                  <Button color='green' onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> 確定
                  </Button>
                </Modal.Actions>
              </Modal>


            </Col>
          </Row>
          <Row style={{
            padding: "5%",
            borderRadius: "15px", boxShadow: "1px 2px 9px #707070", marginBottom: "5%"
          }}>
            <Col span={7}>
              <h1 style={{ fontWeight: "bold", fontSize: 18 }}>牛腩米線</h1>
              <p style={{ fontSize: 12 }}>米線</p>
              <p style={{ fontSize: 12 }}>凍檸茶</p>
            </Col>
            <Col
              span={5}
              style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
              x 1
            </Col>
            <Col span={6}>
              <h1
                style={{ fontWeight: "500", fontSize: 18, textAlign: "right" }}>
                $45
              </h1>
            </Col>
            <Col span={6}>
              <Modal closeIcon open={open} trigger={<Button circular icon="x" style={{
                float: "right",
                fontSize: 10,
              }}
              />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
              >
                <Header icon='archive' content='確定刪除？' />
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove' /> 取消
                  </Button>
                  <Button color='green' onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> 確定
                  </Button>
                </Modal.Actions>
              </Modal>

            </Col>
          </Row>

        </div>
        {/* other interest */}
        <Row
          style={{
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
          }}
        >
          <Col span={12}>
            <h3
              style={{
                paddingTop: "5%",
                paddingBottom: "5%",
                color: "#176d49",
              }}
            >
              你或許感興趣...
            </h3>
            <OtherInterest />
          </Col>
        </Row>
        <Button
          class="ui button"
          href="/OrderHistory"
          nClick={() => console.log("click")}
          style={{ backgroundColor: "#016D49", color: "white" }}
        >
          過往訂單
        </Button>
      </Row>
      <Footer1 />
    </>
  );
};

export default Edit;
