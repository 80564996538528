import React from "react";
// import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, Checkbox, Divider, List, Typography, Descriptions, Card, Space, Button, Image, Form, Input } from "antd";
import { DownloadOutlined, LeftCircleFilled } from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { HeaderTop } from "../../components/HeaderTop";

const data = [
  'Racing car sprays burning fuel into crowd.',
  'Japanese princess to wed commoner.',
  'Australian walks 100km after outback crash.',
  'Man charged over missing wedding girl.',
  'Los Angeles battles huge wildfires.',
];


const inputStyle = {
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'solid',
  borderColor: '#707070',
  borderWidth: '1px',
  borderRadius: 0,
  fontSize: 18,
};

const ResgiterPersonalInfo = () => {

  return (
    <>
      <div>
        <HeaderTop />
      

        <Row style={{ margin: 15 }}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
              }}
            >
              <Row style={{ margin: -10 }}>
                <Col span={24}>
                  <p style={{ textAlign: "center", fontSize: 28, fontFamily: "Arial", fontWeight: "bold", paddingTop: 40 }}>個人資料 </p>
                </Col>
                <Col span={24}>
                  <Form
                    name="basic"

                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      marginLeft: 25, marginRight: 25
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label=""
                      name="phone"

                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input placeholder="電話號碼" style={inputStyle} />
                    </Form.Item>

                    <Form.Item
                      label=""
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: '密碼',
                        },
                      ]}
                    >
                      <Input.Password placeholder="密碼" style={inputStyle} />
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 30
                      }}
                    >
                      <Button type="primary" htmlType="submit" style={{ backgroundColor: "#016D49" }} size={"large"}>
                        <span style={{ color: "#FFFFFF", paddingLeft: 30, paddingRight: 30 }}>提交</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24}>
                  <p style={{ textAlign: "center", fontSize: 16, paddingBottom: 20, paddingTop: 25 }}>此收集個人資料用於本店優惠作用 <span style={{ fontSize: 18, color: "#016D49" }}>跳過</span> </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

      </div>

    </>
  );
};

export default ResgiterPersonalInfo;
