import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {Col, Row, Divider, Checkbox, Form, Radio} from 'antd';
import {Header1} from '../../components/Header1';
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
let {path} = require('../../public/public.json');

const FoodDetails = () => {
    const [drink, setDrink] = useState([]);
    const [match, setMatch] = useState([]);
    const [foodDetails, setFoodDetails] = useState([]);

    const [drinkRadio, setDrinkRadio] = useState(0);
    const [matchRadio, setMatchRadio] = useState(0);

    const [matchTotal, setMatchTotal] = useState(0);
    const [drinkTotal, setDrinkTotal] = useState(0);

    const [matchName, setMatchName] = useState('');
    const [drinkName, setDrinkName] = useState('');

    const [selectedDrink, setSelectedDrink] = useState({});
    const [selectedMatch, setSelectedMatch] = useState({});

    const [total, setTotal] = useState(0);

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    useEffect(() => {
        getFoodDetails();
    }, []);

    useEffect(() => {
        setSessionStorage();
    }, [
        matchTotal,
        drinkTotal,
        total,
        matchName,
        drinkName,
        selectedDrink,
        selectedMatch,
        foodDetails,
    ]);

    const getFoodDetails = async () => {
        const formData = new FormData();
        formData.append('good_id', params.good_id);
        await axios.post(`${path}/getGoodsInfo.php`, formData).then(res => {
            let [data] = res.data;
            console.log('food', data);
            setFoodDetails(data);
        });
        axios.post(`${path}/getGoodDrinkInfo.php`, formData).then(res => {
            let {data, status} = res;
            console.log('drink', data);
            setDrink(data);
        });
        axios.post(`${path}/getGoodsMatch.php`, formData).then(res => {
            let {data, status} = res;
            setMatch(data);
        });
    };

    const onDrinkChange = e => {
        let [drinkMoney] = _.filter(drink, {drinkId: e.target.value});
        setDrinkRadio(e.target.value);
        setDrinkTotal(parseInt(drinkMoney.discount));
        setDrinkName(drinkMoney.name);
        setSelectedDrink(drinkMoney);
    };

    const onMatchChange = e => {
        let [matchMoney] = _.filter(match, {match_goods_id: e.target.value});
        setMatchRadio(e.target.value);
        setMatchTotal(parseInt(matchMoney.discount));
        setMatchName(matchMoney.name);
        setSelectedMatch(matchMoney);
    };

    const setSessionStorage = () => {
        let total = 0;
        total = total + parseFloat(params.price);
        total = total + matchTotal;
        total = total + drinkTotal;
        setTotal(total);
        // let optPrice = 0;
        // optPrice = optPrice + selectedDrink.price;
        // let optName = '';
        // optName = selectedDrink.name + ',' + selectedMatch.name;
        // let foodJson = {
        //     name: foodDetails.name,
        //     optName: optName,
        //     optPrice: optPrice,
        //     number: 1,
        //     money: total,
        //     id: foodDetails.id,
        //     price: total,
        //     goodsCode: foodDetails.goodsCode,
        //     peiList: [],
        //     addSort: 1,
        //     isShowAddDan: 0,
        // };
        // let cartArr = [];
        // cartArr.push(foodJson);
        // let json = {
        //     name: foodDetails.name,
        //     matchName,
        //     drinkName,
        //     total,
        // };
        // sessionStorage.setItem('order_goods_Json', JSON.stringify(json));
        // sessionStorage.setItem('one_cart_goods_Json', JSON.stringify(foodJson));
    };

    const DrinkInfo = () => {
        const list = [];
        drink.forEach((value, index) => {
            list.push(
                <Col className="gutter-row" span={12}>
                    <div style={{padding: '8px 0'}}>
                        <Radio value={value.drinkId}>
                            {value.name}{' '}
                            <span style={{color: '#FAD900'}}>
                                +${value.discount}
                            </span>
                        </Radio>
                    </div>
                </Col>
            );
        });
        return list;
    };

    const MatchInfo = () => {
        const list = [];
        match.forEach((value, index) => {
            list.push(
                <Col className="gutter-row" span={8}>
                    <div style={{padding: '8px 0'}}>
                        <Radio value={value.match_goods_id}>
                            {value.name}{' '}
                            <span style={{color: '#FAD900'}}>
                                +${value.discount}
                            </span>
                        </Radio>
                    </div>
                </Col>
            );
        });
        return list;
    };

    const onSubmitCart = () => {
        let total = 0;
        total = total + parseFloat(params.price);
        total += matchTotal;
        total += drinkTotal;

        setTotal(total);
        let optPrice = 0;
        optPrice = optPrice + selectedDrink.price;

        let optName = '';
        if (selectedDrink.name && selectedMatch.name) {
            optName = selectedDrink.name + ',' + selectedMatch.name;
        } else if (selectedDrink.name) {
            optName = selectedDrink.name;
        } else if (selectedMatch.name) {
            optName = selectedMatch.name;
        }
        const now = new Date();
        const timestamp = now.getTime();
        let orderJson = {
            add_time: timestamp,
            name: foodDetails.name,
            optName: optName,
            optPrice: optPrice,
            number: 1,
            money: total,
            id: foodDetails.id,
            price: total,
            goodsCode: foodDetails.goodsCode,
            peiList: [],
            addSort: 1,
            isShowAddDan: 0,
        };
        let OrderArr = [];
        if (sessionStorage.getItem('order_goods_Json')) {
            OrderArr = JSON.parse(sessionStorage.getItem('order_goods_Json'));
        }
        OrderArr.push(orderJson);

        let cartArr = [];
        if (sessionStorage.getItem('cart_goods_Json')) {
            cartArr = JSON.parse(sessionStorage.getItem('cart_goods_Json'));
        }
        let cartJson = {
            add_time: timestamp,
            name: foodDetails.name,
            matchName,
            drinkName,
            total,
        };
        cartArr.push(cartJson);

        sessionStorage.setItem('order_goods_Json', JSON.stringify(OrderArr));
        sessionStorage.setItem('bought_goods_Json', JSON.stringify(cartArr));
        sessionStorage.setItem('cart_goods_Json', JSON.stringify(cartArr));
    };

    return (
        <>
            <HeaderTop page={'FoodDetails'} />
            <div
                style={{
                    padding: 20,
                    height: '85vh',
                    overflowY: 'scroll',
                    boxSizing: 'border-box',
                    scrollbarWidth: 'none', // Hide scrollbar for Firefox
                    msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
                }}>
                <style>
                    {`
                    ::-webkit-scrollbar { 
                        display: none;  /* Safari and Chrome */
                    }
                  `}
                </style>
                <Row>
                    <Col span={24}>
                        <img
                            src={require('../../assets/images/food3.png')}
                            alt="banner"
                            style={{
                                height: 'auto',
                                width: '100vw',
                            }}
                        />
                    </Col>
                </Row>

                <Row style={{paddingTop: 25, paddingBottom: 10, margin: 10}}>
                    <Col span={16}>
                        <h1 style={{fontWeight: 'bold', fontSize: 24}}>
                            {foodDetails.name}
                        </h1>
                    </Col>
                    {/* <Col span={3}></Col> */}
                    <Col span={8}>
                        <h1
                            style={{
                                fontWeight: '900',
                                fontSize: 24,
                                color: '#016D49',
                                textAlign: 'right',
                            }}>
                            ${foodDetails.price}
                        </h1>
                    </Col>
                </Row>

                <img
                    alt="dash"
                    style={{
                        maxHeight: 150,
                        width: '100%',
                    }}
                    src={require('../../assets/images/dash.png')}
                />
                <Form
                    name="basic"
                    // onFinish={onFinish}
                >
                    <Row
                        style={{
                            margin: 10,
                            display: match.length ? 'block' : 'none',
                            // margin: 20
                            // padding: "0 5%",
                            // width: "100vw",
                        }}>
                        <Row>
                            <Col span={24}>
                                <p style={{fontSize: 16, paddingTop: 20}}>
                                    搭配
                                </p>
                            </Col>
                        </Row>

                        <Form.Item
                            name="match"
                            style={{width: '100%'}}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group
                                onChange={onMatchChange}
                                style={{width: '100%'}}>
                                <Row
                                    gutter={[8, 8]}
                                    style={{width: '100%', marginTop: '2%'}}>
                                    <MatchInfo />
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Row>

                    <img
                        alt="dash"
                        style={{
                            maxHeight: 150,
                            width: '100%',
                        }}
                        src={require('../../assets/images/dash.png')}
                    />
                    <Row
                        style={{
                            margin: 10,
                            display: drink.length ? 'block' : 'none',
                        }}>
                        {' '}
                        <Row>
                            <Col span={24} style={{marginTop: 20}}>
                                <p style={{fontSize: 16}}>飲品</p>
                            </Col>
                        </Row>
                        <Form.Item
                            name="drink"
                            style={{
                                width: '100%',
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group
                                onChange={onDrinkChange}
                                style={{width: '100%'}}>
                                <Row
                                    gutter={[8, 8]}
                                    style={{width: '100%', marginTop: '2%'}}>
                                    <DrinkInfo />
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                </Form>
            </div>
            {foodDetails.name ? (
                <Footer1
                    page={'FoodDetails'}
                    total={total}
                    submit={() => onSubmitCart()}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default FoodDetails;
