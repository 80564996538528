
export const Banner = () => {
  return (
    // <div className="banner w-full md:w-1/3 px-6 mx-auto relative flex items-center-justify-between">
    <div className="banner w-full md:w-1/3 px-6 mx-auto relative flex items-center-justify-between">
     
     <img
        src={require("../assets/images/bg1.png")}
        alt="banner"
        style={{
          height: "auto",
          width: "100vw",
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
        }}
      />
    </div>
  );
};
