import React from "react";
import { Button } from 'semantic-ui-react'
// import { Cart } from "../pages/Cart";

export const Footer = () => {
  return (
        <div
        style={{
          paddingTop: 12,
          paddingBottom: 20,
          boxShadow: "1px 2px 9px grey",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "space-evenly",
          alignItems: "center",
          position:"fixed",
          bottom:0,
          width:"100%",
          background:"#ffff"

        }}
      >
          <Button circular icon='shopping cart'  href="/Cart"  onClick={() => console.log("click")} style={{ fontSize: 20, color: "#016D49",backgroundColor: "white", border: "rgb(1, 109, 73) 1px solid", }} />
        <div style={{ fontSize: 28, fontWeight:"bold" }}>$120</div>

        <Button class="ui positive button" href="/Cart"
          onClick={() => console.log("click")}
          style={{
            backgroundColor: "#016D49",
            color: "white",
             padding: "3% 9% ",
            fontSize: 20,
          }}>下單</Button>
      </div>
  );
};

export default Footer;
