import React, { useEffect, useState } from "react";
// import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, Divider, List, notification, Avatar, Card, Radio, Button, Image, Form, Input } from "antd";
import { DownloadOutlined, LeftCircleFilled } from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { HeaderTop } from "../../components/HeaderTop";
import { Label } from "semantic-ui-react";
import axios from "axios";
let { path } = require('../../public/public.json');

const inputStyle = {
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'solid',
  borderColor: '#707070',
  borderWidth: '1px',
  borderRadius: 0,
  fontSize: 18,
};

const AccountInfo = () => {
  const [user, setUser] = useState({
    name: "test",
    phone: "1234",
    sex: 2
  });
  const [uid, setUid] = useState(JSON.parse(sessionStorage.getItem("loginStatus")).uid);
  const [userSex, setUserSex] = useState(JSON.parse(sessionStorage.getItem("loginStatus")).sex);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getAccountInfo();
  }, [])

  const getAccountInfo = () => {
    let loginStatus = JSON.parse(sessionStorage.getItem("loginStatus"))

    let json = { message: 'sucessful', uid: '73', name: 'test', birth: '2010-01-02', sex: '1', phone: "654321478" };
    setUserSex(1)
    setUser(loginStatus);
  }
  const openNotification = () => {
    api.open({
      key: "updatable",
      message: '成功更改',
    });
  }

  const onFinish = (values) => {
    let { name, birth, sex, phone } = values;
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("name", name);
    formData.append("birth", birth);
    formData.append("sex", sex);
    formData.append("phone", phone);

    axios.post(`${path}/updateAccountInfo.php`, formData).then(res => {
      openNotification();
    });
  }

  return (
    <>
      <div>
        {contextHolder}
        <HeaderTop page={"AccountInfo"}/>
        <Row style={{ marginTop: 50 }}>
          <Col span={24} style={{ justifyContent: "center", display: "flex" }}>
            <Avatar size={120} src={require('../../assets/icons/account-icon.png')} />
          </Col>

        </Row>

        <Row style={{ margin: 15 }}>
          <Col span={24}>
            <Form
              name="basic"
              initialValues={JSON.parse(sessionStorage.getItem("loginStatus"))}

              // initialValues={{message: 'sucessful', uid: '73', name: 'test', birth: '2010-01-02', sex: '1', phone: "654321478"}}
              onFinish={onFinish}
            >
              {console.log("user")}
              {console.log("????", user)}
              <Card
                style={{
                  width: "100%",
                  borderRadius: 10,
                }}
              >
                <Row gutter={[8, 8]}>
                  <Col span={8}><span style={{ position: "absolute", bottom: 0, left: 0, fontSize: 18 }}>會員名稱</span> </Col>
                  <Col span={16} >
                    <Form.Item
                      name="name"
                      rules={
                        [
                          {
                            required: true,
                            message: 'Please input your username!',
                          },
                        ]
                      }
                      style={{ marginBottom: -5, fontSize: 18 }}
                    >
                      <Input placeholder={""} style={{ fontSize: 18 }} bordered={false} size={"small"} />
                    </Form.Item>
                  </Col>
                  <Divider style={{ margin: 0, 'border-top': '1px solid #000000' }} />

                  <Col span={8} style={{ marginTop: 20 }}><span style={{ position: "absolute", bottom: 0, left: 0, fontSize: 18 }}>電話</span> </Col>
                  <Col span={16} >
                    <Form.Item
                      name="phone"
                      rules={
                        [
                          {
                            required: true,
                            message: 'Please input your username!',
                          },
                        ]
                      }
                      style={{ marginBottom: -5, fontSize: 18, marginTop: 20 }}
                    >
                      <Input placeholder="" bordered={false} size={"small"} />
                    </Form.Item>
                  </Col>
                  <Divider style={{ margin: 0, 'border-top': '1px solid #000000' }} />

                  <Col span={8} style={{ marginTop: 20 }}><span style={{ position: "absolute", bottom: 0, left: 0, fontSize: 18 }}>出生日期</span> </Col>
                  <Col span={16} >
                    <Form.Item
                      name="birth"
                      rules={
                        [
                          {
                            required: true,
                            message: 'Please input your username!',
                          },
                        ]
                      }
                      style={{ marginBottom: -5, fontSize: 18, marginTop: 20 }}
                    >
                      <Input placeholder="" bordered={false} size={"small"} />
                    </Form.Item>
                  </Col>
                  <Divider style={{ margin: 0, 'border-top': '1px solid #000000' }} />

                  <Col span={8} style={{ marginTop: 20 }}><span style={{ position: "absolute", bottom: 0, left: 0, fontSize: 18 }}>性別</span> </Col>
                  <Col span={16} >
                    <Form.Item
                      name="sex"
                      rules={
                        [
                          {
                            required: true,
                            message: 'Please input your age!',
                          },
                        ]
                      }
                      style={{ marginBottom: -5, fontSize: 18, marginTop: 20 }}
                    >
                      <Radio.Group >
                        <label style={{ color: "#016D49", marginRight: 10, fontSize: 18 }}>男</label>
                        <Radio value={"1"} style={{ color: "#016D49", marginRight: 50 }}></Radio>
                        <label style={{ marginRight: 10, fontSize: 18 }}>女</label><Radio value={"2"}></Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                </Row>
              </Card>

              <Row >
                <Col span={24} style={{ marginTop: 30 }}>
                  <Form.Item>
                    <Row justify="center" align="middle">
                      <Button type="primary" style={{ backgroundColor: "#016D49", width: '50%' }} size={"large"} htmlType="submit">
                        <span style={{ color: "#FFFFFF" }}>保存</span>
                      </Button>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AccountInfo;
