import {Banner} from '../../components/Banner';
// import { Header } from "../../components/Header";
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import {Cate} from '../Cate';
import React, {useState, useEffect} from 'react';
import {Col, Row, Image, Avatar, List, message} from 'antd';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Button} from 'semantic-ui-react';
import VirtualList from 'rc-virtual-list';
import axios from 'axios';
import _ from 'lodash';
import {useLocation, useSearchParams} from 'react-router-dom';
import Cookies from 'js-cookie';

import {height} from '@mui/system';
import {useNavigate} from 'react-router-dom';
let {path} = require('../../public/public.json');

// import { Button } from "antd";
// import { ShoppingCartOutlined } from '@ant-design/icons';

const Error = () => {
    return <>訂單錯誤或無效 請尋求店員協助</>;
};

export default Error;
