import {Banner} from '../../components/Banner';
// import { Header } from "../../components/Header";
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import {Cate} from '../Cate';
import React, {useState, useEffect} from 'react';
import {Col, Row, Image, Avatar, List, message} from 'antd';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Button} from 'semantic-ui-react';
import VirtualList from 'rc-virtual-list';
import axios from 'axios';
import _ from 'lodash';
import {useLocation, useSearchParams} from 'react-router-dom';
import Cookies from 'js-cookie';

import {height} from '@mui/system';
import {useNavigate} from 'react-router-dom';
let {path} = require('../../public/public.json');

// import { Button } from "antd";
// import { ShoppingCartOutlined } from '@ant-design/icons';

const HandleCode = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const fetchData = async () => {
        const formData = new FormData();
        console.log(queryParams.get('t') != null);
        console.log(queryParams.get('o') != null);
        console.log(
            queryParams.get('t') != null && queryParams.get('o') != null
        );

        if (queryParams.get('t') != null && queryParams.get('o') != null) {
            formData.append(
                'tableCode',
                atob(decodeURIComponent(queryParams.get('t')))
            );
            formData.append('id', queryParams.get('o'));
            Cookies.set('tableCode', queryParams.get('t'), {
                expires: 60 * 60 * 180,
            });
            Cookies.set('orderId', queryParams.get('o'), {
                expires: 60 * 60 * 180,
            });
            await axios
                .post(`${path}/getOrderRecord.php`, formData)
                .then(res => {
                    if (res.data.payStatus >= 0 && res.data.payStatus <= 2) {
                        //在可點餐狀態
                        navigate(`/`);
                    } else {
                        navigate(`/404`);
                        sessionStorage.clear();
                    }
                });
        } else {
            navigate(`/404`);
            sessionStorage.clear();
        }
        sessionStorage.clear();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <>訂單錯誤或無效 請尋求店員協助</>;
};

export default HandleCode;
