import {Banner} from '../../components/Banner';
// import { Header } from "../../components/Header";
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import {Cate} from '../Cate';
import React, {useState, useEffect} from 'react';
import {Col, Row, Image, Avatar, List, message} from 'antd';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Button} from 'semantic-ui-react';
import VirtualList from 'rc-virtual-list';
import axios from 'axios';
import _ from 'lodash';
import {useLocation, useSearchParams} from 'react-router-dom';
import Cookies from 'js-cookie';

import {height} from '@mui/system';
import {useNavigate} from 'react-router-dom';
let {path} = require('../../public/public.json');

// import { Button } from "antd";
// import { ShoppingCartOutlined } from '@ant-design/icons';

const Home = () => {
    const fakeDataUrl =
        'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo';
    let ContainerHeight = window.innerHeight * 0.5;
    if (window.innerHeight < 800) {
        ContainerHeight = window.innerHeight * 0.45;
    }
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const [goods, setGoods] = useState([]);
    const [data, setData] = useState([]);
    const [filterGoods, setFilterGoods] = useState([]);
    const [cateName, setCateName] = useState('');
    const [cate, setCate] = useState();
    const [cateData, setCateData] = useState([]);
    const [tableCode, setTableCode] = useState('');
    const fetchData = async () => {
        if (queryParams.get('tableCode') != null) {
            sessionStorage.clear();
            navigate(
                `/code?t=${queryParams.get('tableCode')}&o=${queryParams.get(
                    'orderId'
                )}`
            );
        } else if (
            Cookies.get('tableCode') != null &&
            Cookies.get('orderId') != null
        ) {
            console.log(Cookies.get('tableCode'));
            setTableCode(atob(decodeURIComponent(Cookies.get('tableCode'))));
        } else {
            navigate(`/404`);
        }
        await axios.get(`${path}/cate.php`).then(res => {
            let {data, status} = res;
            setCateData(data);
            setCateName(data[0].name);
        });
        await axios.get(`${path}/goods.php`).then(res => {
            let {data, status} = res;
            setGoods(data);
        });
    };

    const appendData = () => {
        // fetch(fakeDataUrl)
        //     .then(res => res.json())
        //     .then(body => {
        //         setData(data.concat(body.results));
        //         message.success(`${body.results.length} more items loaded!`);
        //     });
    };
    const onScroll = e => {
        if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
            ContainerHeight
        ) {
            appendData();
        }
    };
    const datas = [
        {id: 1, name: 'John Doe'},
        {id: 2, name: 'Victor Wayne'},
        {id: 3, name: 'Jane Doe'},
    ];

    const filterGood = () => {
        let arr = [];
        _.forEach(cateData, function (value, index) {
            let getGoods = _.filter(goods, {cateId: value.id});
            arr.push(getGoods);
        });
        setFilterGoods(arr);
    };

    const getGood = cateID => {
        let getGoods = _.filter(goods, {cateId: cateID});
    };

    const getCateValue = value => {
        let {name} = value;
        setCateName(name);
    };

    const cateLists = () => {
        const list = [];
        if (cateData)
            cateData.forEach((value, index) => {
                list.push(
                    <Tab
                        key={index}
                        style={{
                            background: cateName == value.name ? '#FFFFFF' : '',
                        }}
                        onClick={() => getCateValue(value)}>
                        {value.name}{' '}
                    </Tab>
                );
            });

        return list;
    };
    const onclickGoods = value => {
        let {id, price} = value;
        navigate(`/FoodDetails?good_id=${id}&price=${price}`);
    };

    const goodsLists = () => {
        const list = [];
        filterGoods.forEach((value, index) => {
            list.push(
                <TabPanel style={{marginLeft: 15}}>
                    <div
                        style={{
                            textAlign: 'left',
                            position: 'relative',
                            left: 0,
                            bottom: 0,
                        }}>
                        <h3 style={{fontSize: 14}}>{cateName}</h3>
                    </div>
                    <List>
                        <VirtualList
                            data={value}
                            height={ContainerHeight}
                            itemHeight={50}
                            itemKey="email"
                            // onScroll={onScroll}
                            style={{width: '100%'}}>
                            {item => (
                                <List.Item>
                                    <div>
                                        <Row>
                                            <Col span={24}>
                                                <Row style={{margin: 0}}>
                                                    <Col span={8}>
                                                        <img
                                                            src={require('../../assets/images/food1.png')}
                                                            alt="food"
                                                            style={{
                                                                // width: 70,
                                                                height: '7vh',
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={16}>
                                                        <Row style={{}}>
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col
                                                                        span={
                                                                            20
                                                                        }
                                                                        style={{
                                                                            width: 'auto',
                                                                        }}>
                                                                        <span
                                                                            style={{}}>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </span>
                                                                    </Col>
                                                                    <Col
                                                                        span={
                                                                            4
                                                                        }>
                                                                        {/* <Avatar
                                      style={{
                                        backgroundColor: '#016D49',
                                        fontSize: 3
                                      }}
                                      size={16}
                                    >
                                      <span style={{ marginBottom: 100, textAlign: "center", justifyContent: "center" }}>2</span>
                                    </Avatar> */}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span>
                                                                    $
                                                                    {item.price}
                                                                </span>
                                                            </Col>
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    marginRight: 100,
                                                                }}>
                                                                <Button
                                                                    onClick={() =>
                                                                        onclickGoods(
                                                                            item
                                                                        )
                                                                    }
                                                                    circular
                                                                    icon="add"
                                                                    style={{
                                                                        float: 'right',
                                                                        fontSize: 10,
                                                                        color: '#016D49',
                                                                        backgroundColor:
                                                                            'white',
                                                                        border: 'rgb(1, 109, 73) 1px solid',
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <Button onClick={() => console.log(item.id)} circular icon='add' style={{ float: "right", fontSize: 10, color: "#016D49", backgroundColor: "white", border: "rgb(1, 109, 73) 1px solid" }} /> */}
                                </List.Item>
                            )}
                        </VirtualList>
                        <div style={{}}>
                            <h3 style={{fontSize: 14}}> </h3>
                        </div>
                    </List>
                </TabPanel>
            );
        });
        return list;
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterGood();
    }, [cateData, goods]);
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <>
            {/* <Row>
        <Col span={24} style={{ backgroundColor: "#016D49", height: 48 }}>
          <Row gutter={[8, 8]} justify="start">
            <Col span={12} style={{ paddingLeft: 14 }}>
              <Image
                width={48}
                src={require("../../assets/icons/record.png")}
              />
            </Col>

            <Col span={12} style={{ paddingLeft: 14 }}>
              <Row justify="end" align="middle">
                <Image
                  width={48}
                  src={require("../../assets/icons/member.png")}
                />
                <p style={{ color: "#FFFFFF", paddingRight: 14}}>登入</p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}
            {/* <HeaderTop /> */}

            <HeaderTop
                page={'Home'}
                leftImage={'../assets/icons/record.png'}
                rightImage={'../assets/icons/member.png'}
            />
            <div>
                <Row>
                    <Col span={24} style={{color: '#000000', height: '8vh'}}>
                        <p
                            style={{
                                fontFamily: 'Segoe UI',
                                textAlign: 'center',
                                padding: 5,
                                fontWeight: 'bold',
                                fontSize: 28,
                            }}>
                            BOHVAN
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} style={{color: '#000000'}}>
                        <p
                            style={{
                                fontFamily: 'Segoe UI',
                                textAlign: 'left',
                                fontSize: 16,
                                paddingLeft: 14,
                                paddingBottom: 12,
                            }}>
                            檯號：
                            {tableCode}
                        </p>
                    </Col>
                </Row>

                <Row style={{height: '20vh'}}>
                    <Col span={24} style={{color: '#000000'}}>
                        <Banner />
                    </Col>
                </Row>

                {/* <div
        style={{
          paddingTop: 50,
          paddingLeft: 50,
          paddingRight: 50,
          paddingBottom: 20,
        }}
      >
        <h1 style={{ fontWeight: "600", fontSize: 25 }}>Weclome to MMI</h1>
        <br />
        <p
          style={{
            fontWeight: "400",
            fontSize: 12,
            position: "absolute",
            // right: 40,
          }}
        >
          檯號：A001
        </p>
       
      </div> */}
                {/* <Banner /> */}
                {/* <Cate cate={cate} /> */}
                <Row style={{height: ContainerHeight}}>
                    <Tabs
                        selectedIndex={tabIndex}
                        style={{width: '100vw'}}
                        onSelect={index => setTabIndex(index)}>
                        <Col
                            span={8}
                            orientation="vertical"
                            variant="scrollable">
                            <TabList
                                style={{
                                    borderTopLeftRadius: 10,
                                    display: 'flex',
                                    height: ContainerHeight,
                                    overflowY: 'auto',
                                }}
                                orientation="vertical"
                                variant="scrollable">
                                {cateLists()}
                            </TabList>
                        </Col>
                        <Col style={{height: ContainerHeight, width: '56vw'}}>
                            {goodsLists()}
                        </Col>
                    </Tabs>
                </Row>
            </div>
            <Footer1 page={'Home'} />
        </>
    );
};

export default Home;
