/* eslint-disable no-unreachable */

import React from "react";
// import { Col, Row, Image, Button, Popover, Menu } from "antd";

export const Header = () => {
  return 
  (
   <>
    <div style={{ paddingTop: 80,paddingLeft: 50,paddingRight:50, paddingBottom: 20 }}>
    <h1 style={{ fontWeight: "600", fontSize: 25 }}>
      Weclome to MMI
    </h1>
    <br />
    <p style={{fontWeight: "400",  fontSize: 12, position:'absolute',right: 40}}>
    檯號：A001
    </p>
  </div>
   </>
  )
}
export default Header;
