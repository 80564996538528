import React, { useEffect, useState } from "react";
import { Col, Row, Checkbox, Divider, List, Typography, Descriptions, Card, Space, Button, Image, Avatar } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { HeaderTop } from "../../components/HeaderTop";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
let { path } = require('../../public/public.json');

const CouponInfo = () => {
  const [data, setData] = useState([]);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let coupon_id = params.coupon_id;

  useEffect(() => {
    getCouponInfo();
  }, [params.action])

  const getCouponInfo = () => {
    const formData = new FormData();
    formData.append("coupon_id", coupon_id);

    axios.post(`${path}/getCouponInfo.php`, formData).then(res => {
      let [data] = res.data;
      setData(data);
    });
  }

  const exchangeCoupon = () => {
    let loginStatus = JSON.parse(sessionStorage.getItem("loginStatus"))
    const formData = new FormData();
    formData.append("uid", loginStatus.uid);
    formData.append("coupon_id", coupon_id);
    axios.post(`${path}/createCouponUser.php`, formData).then(res => {
      //..... 
      // user score - coupon score
    });
  }

  return (
    <>
      <HeaderTop page={"CouponInfo"} />
      <Row style={{ margin: 5 }}>
        <Col span={24}>
          <img alt="example" style={{ maxHeight: 200, width: "100%" }} src={require('../../assets/icons/coupon-default.jpg')} />
        </Col>
      </Row>

      <Row style={{ margin: 15, marginTop: -55 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
            }}
          >
            <Row >
              <Col span={24}>
                <Row justify="center" align="middle">
                  <span style={{ fontSize: 18 }}>{data.name}</span>
                </Row>

                <Row justify="center" align="middle">
                  <span style={{ fontSize: 16, color: "#6A6A6A", marginTop: 20 }}>有效期：{data.enddate}</span>
                </Row>
                {
                  params.action == "exhange" ?
                    <>
                      <Row justify="center" align="middle">
                        <Button type="primary" style={{ backgroundColor: "#016D49", width: '50%', marginTop: 20 }} size={"large"} onClick={()=> exchangeCoupon()}>
                          <span style={{ color: "#FFFFFF" }}>{data.score}積分換領</span>
                        </Button>
                      </Row>
                    </>
                    :
                    ""
                }
                {/* <Row justify="center" align="middle">
                  <Button type="primary" style={{ backgroundColor: "#016D49", width: '50%', marginTop: 20 }} size={"large"}>
                    <span style={{ color: "#FFFFFF" }}>200積分換領</span>
                  </Button>
                </Row> */}

                <Row justify="center" align="middle">
                  <span style={{ fontSize: 28, color: "#016D49", marginTop: 20 }}>{data.code}</span>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginLeft: 15, marginRight: 15 }}>
        <Col sm={24}>
          <span style={{ fontSize: 14, color: "#707070" }}>
            {data.info}
            {/* 使用條件 :
            -此禮券只適用於2023年3月1日。 -此禮券為於門市購物滿淨價HK$50，即減HK$10之優惠。 -禮券的倒數時計開始後，禮券將不能撤回、重用或補發。倒數時效過 期後禮券隨即失效。 - 此優惠不可用於購買個別產品、會員獨家優惠產品、禮券、美容療程 髮型服務、餐飲服務及批發產品。 - 此優惠不可與其他優惠券或銀行優惠同時使用。 - 此優惠只限使用一次，每次購物只限使用一張。 - 電子優惠券不可轉讓，亦不可兌換現金或其他貨品。 - 如退貨後適用產品金額未達到該電子優惠券指定金額，顧客將不能享 用該電子優惠券優惠。 - 必須於有效期前使用，逾期無效，不得延期。 - 請小心保管電子優惠券，如有遺失或任何其他原因而導致被第三者冒 用及或盜取，莎莎化粧品有限公司恕不負責，亦不設補發及退款。 - 優惠券不可轉售或作其他商業用途。 - 如有任何問題，莎莎化粧品有限公司有權修改以上條款及細則而無需 另外通知。 - 如有任何爭議，有限公司保留最終決定權。 - 客戶服務熱線(852)1234 4567(辦公時間內) */}
          </span>
        </Col>
      </Row>

    </>
  );
};

export default CouponInfo;
