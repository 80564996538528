import React, {useState, useEffect} from 'react';
import {LeftOutlined} from '@ant-design/icons';
import 'semantic-ui-css/semantic.min.css';
import {Col, Row, Image} from 'antd';
import {useNavigate} from 'react-router-dom';

export const HeaderTop = ({page}) => {
    const [leftImage, setLeftImage] = useState('');
    const navigate = useNavigate();
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    useEffect(() => {
        console.log(page);
        console.log('params', params.list);
    }, []);

    let loginStatus = JSON.parse(sessionStorage.getItem('loginStatus'));

    useEffect(() => {
        if (loginStatus == null) {
            sessionStorage.setItem('login', 0);
            return;
        }
    }, [loginStatus]);

    const handleLoginOnClick = () => {
        if (params.action == 'exchange') {
            navigate(`/Coupon?list=1`);
            return;
        }
        if (params.action == 'usage') {
            navigate(`/Coupon`);
            return;
        }
        if ((page == 'Coupon' || page == 'CouponInfo') && params.list == null) {
            navigate(`/Coupon?list=1`);
            return;
        }

        if (page == 'Coupon' && params.list != null) {
            navigate(`/Coupon`);
            return;
        }

        if (loginStatus != null) {
            navigate(`/Account`);
            return;
        }
        navigate(`/Login`);
    };

    return (
        <Row style={{height: '6.5vh', minHeight: 48}}>
            <Col span={24} style={{backgroundColor: '#016D49', height: 48}}>
                <Row justify="start">
                    <Col span={12} style={{paddingLeft: 14}}>
                        {page == 'Home' ? (
                            <>
                                <Col onClick={() => navigate(`/OrderHistory`)}>
                                    <Image
                                        preview={false}
                                        width={48}
                                        src={require('../assets/icons/record.png')}
                                    />
                                </Col>
                            </>
                        ) : (
                            <>
                                <Row
                                    justify="left"
                                    align="middle"
                                    style={{marginTop: 10}}
                                    onClick={() => navigate(`/`)}>
                                    <Image
                                        preview={false}
                                        width={35}
                                        src={require('../assets/icons/back.png')}
                                    />
                                    <p
                                        style={{
                                            color: '#FFFFFF',
                                            paddingRight: 16,
                                        }}>
                                        返回
                                    </p>
                                </Row>
                            </>
                        )}

                        {/* <Image
                            width={48}
                            src={require('../assets/icons/record.png')}
                        /> */}
                        {/* <p style={{ color: "#FFFFFF", paddingRight: 14 }}>返回</p> */}

                        {/* <Image
                            width={48}
                            src={require('../assets/icons/record.png')}
                        /> */}
                    </Col>

                    <Col span={12} style={{paddingLeft: 14}}>
                        <Row justify="end" align="middle">
                            {page == 'Home' ||
                            page == 'Login' ||
                            page == 'Register' ||
                            page == 'OrderSuccess' ||
                            page == 'FoodDetails' ||
                            page == 'OrderHistory' ? (
                                <>
                                    <Col onClick={() => handleLoginOnClick()}>
                                        <Image
                                            preview={false}
                                            width={48}
                                            src={require('../assets/icons/member.png')}
                                        />
                                    </Col>
                                    <Col>
                                        <p
                                            style={{
                                                color: '#FFFFFF',
                                                paddingRight: 14,
                                            }}>
                                            {loginStatus == null
                                                ? '登入'
                                                : '會員'}
                                        </p>
                                    </Col>
                                </>
                            ) : (
                                ''
                            )}

                            {page == 'Account' ||
                            page == 'AccountInfo' ||
                            page == 'BookingRecord' ? (
                                <>
                                    <Col
                                        onClick={() =>
                                            navigate(`/OrderHistory`)
                                        }>
                                        <Image
                                            preview={false}
                                            width={48}
                                            src={require('../assets/icons/record.png')}
                                        />
                                    </Col>
                                    <Col onClick={() => handleLoginOnClick()}>
                                        <Image
                                            preview={false}
                                            width={48}
                                            src={require('../assets/icons/member.png')}
                                        />
                                    </Col>
                                    <Col>
                                        <p
                                            style={{
                                                color: '#FFFFFF',
                                                paddingRight: 14,
                                            }}>
                                            {loginStatus == null
                                                ? '登入'
                                                : '會員'}
                                        </p>
                                    </Col>
                                </>
                            ) : (
                                ''
                            )}

                            {page == 'Coupon' || page == 'CouponInfo' ? (
                                <>
                                    <Col onClick={() => handleLoginOnClick()}>
                                        <p
                                            style={{
                                                color: '#FFFFFF',
                                                paddingRight: 14,
                                                marginTop: 15,
                                            }}>
                                            {params.list != null ||
                                            params.action == 'usage'
                                                ? '我的'
                                                : '領取禮券'}
                                        </p>
                                    </Col>
                                </>
                            ) : (
                                ''
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default HeaderTop;
