import React, {useState, useEffect} from 'react';
import {Col, Row, Image} from 'antd';
import {Header1} from '../../components/Header1';
import {HeaderTop} from '../../components/HeaderTop';
import {Footer1} from '../../components/Footer1';
import axios from 'axios';
import _ from 'lodash';
import Cookies from 'js-cookie';
import moment from 'moment';

let {path} = require('../../public/public.json');

// import { Card, Image, List } from "semantic-ui-react";

const OrderHistory = () => {
    const [history, setHistory] = useState([]);
    const [total, setTotal] = useState(0);
    const [historyList, setHistoryList] = useState(0);

    const tableCode = Cookies.get('tableCode');
    const getHistory = async () => {
        const formData = new FormData();
        formData.append('tableCode', atob(decodeURIComponent(tableCode)));
        await axios.post(`${path}/getTableOrder.php`, formData).then(res => {
            let [data] = res.data;
            if (_.isUndefined(data)) return;
            let {foodJson} = data;
            setHistory(res.data);

            let arr = [];
            res.data.forEach((value, index) => {
                arr.push(
                    <Row style={{}} key={index}>
                        <Col span={13}>
                            <h1
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    margin: 0,
                                    marginTop: 2.5,
                                    marginBottom: 2.5,
                                }}>
                                {value.name}
                            </h1>
                            <Opt opt={value.optName} />
                        </Col>
                        <Col
                            span={4}
                            style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                                textAlign: 'right',
                                marginTop: 5,
                                marginBottom: 2.5,
                            }}>
                            x 1
                        </Col>
                        <Col span={7}>
                            <h1
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    textAlign: 'right',
                                    marginTop: 5,
                                    marginBottom: 2.5,
                                }}>
                                ${value.price}
                            </h1>
                        </Col>
                    </Row>
                );
            });

            setHistoryList(arr);
            let count = 0;
            res.data.forEach(item => {
                count += parseInt(item.price);
            });
            setTotal(count);
        });
    };
    useEffect(() => {
        getHistory();
    }, []);
    const Opt = ({opt}) => {
        const list = [];
        let options = opt.split(',');
        options.forEach((value, index) => {
            if (value != 'undefined' && value != null && value != '')
                list.push(<p style={{fontSize: 12, margin: 0}}>{value}</p>);
        });
        return list;
    };

    useEffect(() => {
        // populate history
        console.log();
    }, [history, historyList]);
    return (
        <>
            <HeaderTop page={'OrderHistory'} />
            <Row
                style={{
                    width: '100vw',
                    height: '80vh',
                    padding: '1% 5%',
                    paddingTop: '3%',
                    overflowY: 'scroll',
                }}
                className={'orderHisory'}>
                <div
                    style={{
                        width: '90vw',
                        boxShadow: '1px 2px 9px #707070',
                        borderRadius: '2%',
                    }}>
                    <Row
                        style={{
                            width: '100%',
                            padding: '0 10%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            marginTop: '13%',
                        }}>
                        <Col span={8}>
                            <h4
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    color: '#016D49',
                                }}>
                                訂單紀錄
                            </h4>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}>
                                <p
                                    style={{
                                        fontSize: 15,
                                        textAlign: 'right',
                                    }}>
                                    {atob(decodeURIComponent(tableCode))}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '2.5%',
                            paddingBottom: '2.5%',
                        }}>
                        <img
                            alt="dash"
                            style={{
                                maxHeight: 150,
                                width: '80%',
                            }}
                            src={require('../../assets/images/dash.png')}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            paddingRight: '10%',
                            paddingLeft: '10%',
                        }}>
                        {historyList
                            ? historyList
                            : '還沒有提交的訂單 快去下單吧!'}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '2.5%',
                            paddingBottom: '2.5%',
                        }}>
                        <img
                            alt="dash"
                            style={{
                                maxHeight: 150,
                                width: '80%',
                            }}
                            src={require('../../assets/images/dash.png')}
                        />
                    </div>
                    <Row
                        style={{
                            width: '100%',
                            paddingRight: '10%',
                            paddingLeft: '10%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            marginTop: '3%',
                            marginBottom: '3%',
                        }}>
                        <Col span={8}>
                            <h4
                                style={{
                                    fontWeight: '500',
                                }}>
                                小計
                            </h4>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <h4
                                style={{
                                    fontWeight: '500',
                                    textAlign: 'right',
                                }}>
                                ${total}
                            </h4>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            width: '100%',
                            paddingRight: '10%',
                            paddingLeft: '10%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            marginTop: '3%',
                            marginBottom: '3%',
                        }}>
                        <Col span={8}>
                            <h3
                                style={{
                                    fontWeight: '900',
                                    fontSize: 20,
                                }}>
                                總計
                            </h3>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <h3
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'right',
                                }}>
                                ${total}
                            </h3>
                        </Col>
                    </Row>
                </div>

                <Row
                    style={{padding: '2%', textAlign: 'center', width: '80vw'}}>
                    {history ? (
                        history.length > 0 ? (
                            <Col span={24}>
                                <p
                                    style={{
                                        fontSize: 14,
                                        color: '#B4B4B4',
                                        padding: '3%',
                                    }}>
                                    最後更新時間{' '}
                                    {moment(
                                        history[history.length - 1].add_time
                                    ).format('HH:mm:ss')}
                                </p>
                            </Col>
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Row>

            <Footer1 page={'OrderHistory'} />
        </>
    );
};

export default OrderHistory;
