import React from 'react';

// import { LeftOutlined } from "@ant-design/icons";
import {
    Col,
    Row,
    Checkbox,
    Divider,
    List,
    Typography,
    Descriptions,
    Card,
    Space,
    Button,
    message,
    Form,
    Input,
} from 'antd';
import {DownloadOutlined, LeftCircleFilled} from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import {Header1} from '../../components/Header1';
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
let {path} = require('../../public/public.json');

const inputStyle = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'solid',
    borderColor: '#707070',
    borderWidth: '1px',
    borderRadius: 0,
    fontSize: 18,
};

const Login = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const handleRegisterOnClick = () => navigate(`/Register`);
    const onFinish = values => {
        let {username, password} = values;

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        axios.post(`${path}/login.php`, formData).then(res => {
            let [data] = res.data;
            let {message, uid, name, phone, sex, birth, score} = data;

            if (message == 'sucessful') {
                let userInfoJson = {
                    uid,
                    name,
                    phone,
                    sex,
                    birth,
                    score,
                    status: 1,
                };
                sessionStorage.setItem(
                    'loginStatus',
                    JSON.stringify(userInfoJson)
                );
                navigate('/');
            }
            error();
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: `電話密碼不正確`,
        });
    };

    return (
        <>
            {contextHolder}
            <div>
                <HeaderTop page={'Login'} />
                <Row>
                    <Col span={24}>
                        <img
                            alt="example"
                            style={{maxHeight: 274, width: '100%'}}
                            src={require('../../assets/icons/login-logout-bg.jpg')}
                        />
                    </Col>
                </Row>

                <Row style={{margin: 15}}>
                    <Col span={24}>
                        <Card
                            style={{
                                width: '100%',
                                borderRadius: 10,
                                marginTop: -130,
                            }}>
                            <Row style={{margin: -10}}>
                                <Col span={24}>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 28,
                                            fontFamily: 'Arial',
                                            fontWeight: 'bold',
                                            paddingTop: 40,
                                        }}>
                                        登入{' '}
                                    </p>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 16,
                                        }}
                                        style={{
                                            marginLeft: 25,
                                            marginRight: 25,
                                        }}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        // onFinishFailed={onFinishFailed}
                                        autoComplete="off">
                                        <Form.Item
                                            label=""
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your username!',
                                                },
                                            ]}>
                                            <Input
                                                placeholder="電話號碼"
                                                style={inputStyle}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label=""
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '密碼',
                                                },
                                            ]}>
                                            <Input.Password
                                                placeholder="密碼"
                                                style={inputStyle}
                                            />
                                        </Form.Item>

                                        <div>
                                            <p
                                                style={{
                                                    textAlign: 'right',
                                                    fontSize: 16,
                                                    marginTop: -15,
                                                    color: '#6C6C6C',
                                                }}>
                                                忘記密碼？
                                            </p>
                                        </div>

                                        <Form.Item
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 30,
                                            }}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{
                                                    backgroundColor: '#016D49',
                                                }}
                                                size={'large'}>
                                                <span
                                                    style={{
                                                        color: '#FFFFFF',
                                                        paddingLeft: 30,
                                                        paddingRight: 30,
                                                    }}>
                                                    登錄
                                                </span>
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={24}>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontSize: 16,
                                            paddingBottom: 20,
                                            paddingTop: 25,
                                        }}>
                                        還沒有會員帳號？現在{' '}
                                        <span
                                            style={{
                                                fontSize: 18,
                                                color: '#016D49',
                                            }}
                                            onClick={() =>
                                                handleRegisterOnClick()
                                            }>
                                            註冊
                                        </span>{' '}
                                        成為會員
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Login;
