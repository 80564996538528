import {useEffect, useState} from 'react';
// import { LeftOutlined } from "@ant-design/icons";
import {
    Col,
    Row,
    Checkbox,
    Divider,
    List,
    Typography,
    Descriptions,
    Card,
    Space,
    Button,
    Image,
    Avatar,
} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {HeaderTop} from '../../components/HeaderTop';
import VirtualList from 'rc-virtual-list';
import axios from 'axios';
let {path} = require('../../public/public.json');

const Coupon = () => {
    const [data, setData] = useState([]);
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const fakeDataUrl = {
        results: [
            {
                coupon_id: 1,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場8折',
                coupon_credit: 200,
            },
            {
                coupon_id: 2,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場7折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場6折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場5折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
            {
                coupon_id: 3,
                image: '',
                expire_date: '2024-02-28',
                coupon_name: '禮券名稱比如全場1折',
                coupon_credit: 200,
            },
        ],
    };

    // const fakeDataUrl =
    //   'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo';
    const ContainerHeight = 400;
    // const appendData = () => {
    //   setData(fakeDataUrl.results);
    //   // fetch(fakeDataUrl)
    //   //   .then((res) => res.json())
    //   //   .then((body) => {
    //   //     setData(data.concat(body.results));
    //   //     message.success(`${body.results.length} more items loaded!`);
    //   //   });
    // };
    const onScroll = e => {
        if (
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
            ContainerHeight
        ) {
            appendData();
        }
    };
    const navigate = useNavigate();
    // const handleOnClick = (coupon_id) => navigate(`/CouponInfo?coupon_id=${coupon_id}`);

    useEffect(() => {
        if (params.list != null) {
            getCouponList();
        } else {
            appendData();
        }
    }, [params]);

    useEffect(() => {
        if (params.list != null) {
            getCouponList();
        }
    });

    const appendData = () => {
        let loginStatus = JSON.parse(sessionStorage.getItem('loginStatus'));
        const formData = new FormData();
        formData.append('uid', loginStatus.uid);
        axios.post(`${path}/getUserCoupon.php`, formData).then(res => {
            setData(res.data);
        });
    };

    const getCouponList = async () => {
        let loginStatus = JSON.parse(sessionStorage.getItem('loginStatus'));
        const formData = new FormData();
        formData.append('uid', loginStatus.uid);
        await axios.post(`${path}/getCouponList.php`, formData).then(res => {
            setData(res.data);
        });
    };

    const handleOnClick = async coupon_id => {
        let loginStatus = JSON.parse(sessionStorage.getItem('loginStatus'));
        const formData = new FormData();
        formData.append('uid', loginStatus.uid);
        formData.append('coupon_id', coupon_id);
        navigate(`/CouponInfo?coupon_id=${coupon_id}&action=usage`);
    };
    const exchangeCoupon = coupon_id => {
        navigate(`/CouponInfo?coupon_id=${coupon_id}&action=exhange`);
    };

    return (
        <>
            <HeaderTop page={'Coupon'} />
            <Row style={{marginTop: 12, marginBottom: 7, marginLeft: 5}}>
                <Col span={24}>
                    <span style={{fontSize: 18, fontWeight: 'bold'}}>
                        {params.list != null ? '' : '我的禮券：'}
                    </span>
                </Col>
            </Row>
            <Row style={{margin: 5}}>
                <Col span={24}>
                    <Card
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 10,
                        }}>
                        <div
                            id="scrollableDiv"
                            style={{
                                height: 700,
                                overflow: 'auto',

                                // padding: '0 16px',
                                // border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}>
                            <List>
                                <VirtualList
                                    data={data}
                                    height={700}
                                    // itemHeight={47}
                                    itemKey="email"
                                    onScroll={onScroll}>
                                    {item => (
                                        <List.Item
                                            key={item.email}
                                            style={{borderBlockEnd: 'none'}}>
                                            <div>
                                                <Row>
                                                    <Col
                                                        span={24}
                                                        style={{
                                                            fontFamily:
                                                                'Segoe UI, Regular',
                                                            color: '#0E0E0E',
                                                            fontSize: 16,
                                                        }}>
                                                        <img
                                                            alt="example"
                                                            style={{
                                                                maxHeight: 150,
                                                                width: '100%',
                                                            }}
                                                            src={require('../../assets/icons/coupon-default.jpg')}
                                                        />
                                                    </Col>
                                                    <Col
                                                        span={14}
                                                        style={{
                                                            fontFamily:
                                                                'Segoe UI, Regular',
                                                            fontSize: 16,
                                                        }}>
                                                        {item.name}
                                                    </Col>
                                                    <Col span={24}>
                                                        <Row
                                                            style={{
                                                                marginTop: 6,
                                                            }}
                                                            // gutter={[8, 8]}
                                                        >
                                                            <Col
                                                                span={14}
                                                                style={{
                                                                    fontFamily:
                                                                        'Segoe UI, Regular',
                                                                    fontSize: 14,
                                                                }}>
                                                                有效期：
                                                                {moment(
                                                                    item.enddate
                                                                ).format(
                                                                    'YYYY-MM-DD'
                                                                )}
                                                            </Col>
                                                            <Col
                                                                span={10}
                                                                style={{
                                                                    fontFamily:
                                                                        'Segoe UI, Regular',
                                                                    fontSize: 14,
                                                                    marginTop:
                                                                        -20,
                                                                }}>
                                                                {item.status ==
                                                                    0 &&
                                                                params.list ==
                                                                    null ? (
                                                                    <>
                                                                        <Button
                                                                            type="primary"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#016D49',
                                                                                width: '100%',
                                                                            }}
                                                                            size={
                                                                                'large'
                                                                            }
                                                                            onClick={() =>
                                                                                handleOnClick(
                                                                                    item.coupon_id
                                                                                )
                                                                            }>
                                                                            <span
                                                                                style={{
                                                                                    color: '#FFFFFF',
                                                                                }}>
                                                                                使用
                                                                            </span>
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {item.status ==
                                                                    1 &&
                                                                params.list ==
                                                                    null ? (
                                                                    <>
                                                                        <Button
                                                                            type="primary"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#E4E4E4',
                                                                                width: '100%',
                                                                            }}
                                                                            size={
                                                                                'large'
                                                                            }>
                                                                            <span
                                                                                style={{
                                                                                    color: '#FFFFFF',
                                                                                }}>
                                                                                已使用
                                                                            </span>
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {params.list !=
                                                                null ? (
                                                                    <>
                                                                        <Button
                                                                            type="primary"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#016D49',
                                                                                width: '100%',
                                                                            }}
                                                                            size={
                                                                                'large'
                                                                            }
                                                                            onClick={() =>
                                                                                exchangeCoupon(
                                                                                    item.coupon_id
                                                                                )
                                                                            }>
                                                                            <span
                                                                                style={{
                                                                                    color: '#FFFFFF',
                                                                                }}>
                                                                                {
                                                                                    item.coupon_credit
                                                                                }
                                                                                積分換領
                                                                            </span>
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </List.Item>
                                    )}
                                </VirtualList>
                            </List>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Coupon;
