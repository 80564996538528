import { useEffect, useState } from 'react';
// import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, message, Divider, List, Skeleton, Descriptions, Card, Space, Button, Image, Avatar } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import InfiniteScroll from 'react-infinite-scroll-component';
import VirtualList from 'rc-virtual-list';
import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { HeaderTop } from "../../components/HeaderTop";
import axios from "axios";
let { path } = require('../../public/public.json');

const CouponUsgeList = () => {
  const [data, setData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const fakeDataUrl = {
    results: [{
      date: "28/02/2023 14:52",
      invoice_id: "IN000202",
      coupon: "",
      invoice_total: 200,
      gain: 200
    }, {
      date: "28/02/2023 14:52",
      invoice_id: "IN000202",
      coupon: "",
      invoice_total: 200,
      gain: -200
    }]
  }

  const info = {
    total_credit: 2000
  }
  // const fakeDataUrl =
  //   'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo';
  const ContainerHeight = 400;
  const appendData = () => {
    axios.get(`${path}/goods.php`).then(function (response) {
      console.log(response.data);
      // navigate('/');
    });
  };
  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      appendData();
    }
  };
  useEffect(() => {
    appendData();
  }, []);
  return (
    <>
      <HeaderTop />

      <Row style={{ margin: 15 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
            }}
          >
            <Row style={{ margin: -10 }}>
              <Col span={12}>
                <Avatar size={120} src={require('../../assets/icons/account-icon.png')} />
              </Col>
              <Col span={12}>
                <Row style={{ marginTop: 30 }}>
                  <Col span={24} style={{ fontFamily: "Segoe UI, Regular", fontSize: 16 }}>會員名稱</Col>
                  <Col span={24} style={{ fontFamily: "Segoe UI, Regular", fontSize: 16, paddingTop: 10, color: "#6A6A6A" }}>積分 <span style={{ color: 'green' }}>{infoData.total_credit}</span></Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ margin: 15 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
            }}
          >
            <Card.Grid
              style={{
                width: '100%',
                borderRadius: 10,
              }}>
              <div
                id="scrollableDiv"
                style={{
                  height: 400,
                  overflow: 'auto',
                }}
              >
                <List>
                  <VirtualList
                    data={data}
                    height={ContainerHeight}
                    itemHeight={47}
                    itemKey="email"
                    onScroll={onScroll}
                  >
                    {(item) => (
                      <List.Item key={item.email} style={{ borderBlockEnd: 'none' }}>
                        <List.Item.Meta
                        />
                        <div>
                          <Row>
                            <Col span={24} style={{ paddingTop: 6, paddingLeft: 10 }}>
                              <span style={{ color: "#6A6A6A" }}>日期：{item.date}</span>
                            </Col>
                            <Col span={24} style={{ paddingTop: 10, paddingLeft: 10 }}>
                              <span style={{ color: "#6A6A6A" }}>單號：{item.invoice_id}</span>
                            </Col>

                            <Col span={24} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                              <Row gutter={[8, 8]}>
                                <Col span={14} style={{ color: "#6A6A6A" }}> 結單：${item.invoice_total}</Col>
                                <Col span={10} style={{ textAlign: 'right' }}> <span style={{ color: "#016D49" }}>獲得：{item.gain}</span></Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider style={{ marginTop: 5, 'border-top': '1px dashed #016D49' }} />
                        </div>
                      </List.Item>
                    )}
                  </VirtualList>
                </List>
              </div>
              {/* <Row>
                <Col span={24} style={{ paddingTop: 6, paddingLeft: 10 }}>
                  <span style={{ color: "#6A6A6A" }}>日期：28/02/2023 14:52</span>
                </Col>
                <Col span={24} style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <span style={{ color: "#6A6A6A" }}>單號：IN000202</span>
                </Col>

                <Col span={24} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                  <Row gutter={[8, 8]}>
                    <Col span={14} style={{ color: "#6A6A6A" }}> 結單：$200</Col>
                    <Col span={10} style={{ textAlign: 'right' }}> <span style={{ color: "#016D49" }}>獲得：+200</span></Col>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ marginTop: 5, 'border-top': '1px dashed #016D49' }} />
              <Row>
                <Col span={24} style={{ paddingTop: 6, paddingLeft: 10 }}>
                  <span style={{ color: "#6A6A6A" }}>日期：28/02/2023 14:52</span>
                </Col>
                <Col span={24} style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <span style={{ color: "#6A6A6A" }}>單號：IN000202</span>
                </Col>

                <Col span={24} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                  <Row gutter={[8, 8]}>
                    <Col span={14} style={{ color: "#6A6A6A" }}> 結單：$200</Col>
                    <Col span={10} style={{ textAlign: 'right' }}> <span style={{ color: "#DA5C97" }}>扣減：-100</span></Col>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ marginTop: 5, 'border-top': '1px dashed #016D49' }} /> */}
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CouponUsgeList;
