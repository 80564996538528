import React, {useEffect, useState} from 'react';
import {Button} from 'semantic-ui-react';
import {
    useNavigate,
    BrowserRouter as Router,
    Route,
    Link,
    Routes,
} from 'react-router-dom';
import {Image, Spin} from 'antd';
import _ from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import {LoadingOutlined} from '@ant-design/icons';
let {path} = require('../public/public.json');

export const Footer1 = ({page, submit, totalPrice}) => {
    const navigate = useNavigate();
    const [foodDetails, setFoodDetails] = useState([]);
    const [onSubmit, setOnSubmit] = useState(false);
    const [foodJson, setFoodJson] = useState({
        name: '',
        matchName: '',
        drinkName: '',
        total: 0,
    });

    const [total, setTotal] = useState(0);
    const handleSubmit = () => {
        if (page == 'FoodDetails') {
            submit();
            navigate(`/Cart`);
        } else if (page == 'OrderSuccess') {
            navigate(`/OrderHistory`);
        } else if (page == 'Home') {
            navigate(`/OrderHistory`);
        } else if (page == 'Cart') {
            submit();
        } else {
            navigate(`/`);
        }
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    useEffect(() => {
        checkIsOrderAvb();
        if (sessionStorage.getItem('order_goods_Json') != null) {
            setFoodDetails(
                JSON.parse(sessionStorage.getItem('order_goods_Json'))
            );
            setFoodJson(JSON.parse(sessionStorage.getItem('order_goods_Json')));
            // setTotal(foodDetails.total);
        }
    }, []);

    useEffect(() => {
        checkIsOrderAvb();

        if (page == 'Home') return;
        if (page == 'OrderHistory') {
            return;
        }

        if (foodDetails.length > 0) {
            let count = 0;
            foodDetails.forEach(element => {
                count += element.price;
            });
            setTotal(count);
        }
    }, [foodDetails]);

    const checkIsOrderAvb = async () => {
        const formData = new FormData();
        if (
            Cookies.get('tableCode') != null &&
            Cookies.get('orderId') != null
        ) {
            formData.append('id', Cookies.get('orderId'));
            await axios
                .post(`${path}/getOrderRecord.php`, formData)
                .then(res => {
                    if (res.data.payStatus >= 0 && res.data.payStatus <= 2) {
                        setTotal(res.data.goodsTotalMoney);
                    } else {
                        navigate(`/404`);
                        sessionStorage.clear();
                    }
                });
        } else {
            navigate(`/404`);
            sessionStorage.clear();
            //no sessionStorage currentOrderId
        }
    };

    return (
        <div
            style={{
                paddingTop: 12,
                paddingBottom: 20,
                boxShadow: '1px 2px 9px grey',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                position: 'fixed',
                bottom: 0,
                width: '100%',
                background: '#ffff',
            }}>
            <div style={{position: 'relative'}}>
                <div
                    style={{
                        position: 'absolute',
                        width: 20,
                        height: 20,
                        background: '#016D49',
                        top: -5,
                        right: -5,
                        zIndex: 1,
                        borderRadius: 100,
                        textAlign: 'center',
                        color: '#fff',
                        fontSize: 12,
                    }}>
                    {foodDetails.length}
                </div>
                <Image
                    onClick={() => navigate(`/cart`)}
                    preview={false}
                    width={48}
                    src={require('../assets/icons/shoppingcart.png')}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    alignItems: 'flex-start',
                }}>
                <h3>總金額：${total}</h3>
            </div>
            {/* <Link
                //to="/OrderHistory"
                style={{
                    backgroundColor: '#016D49',
                    color: 'white',
                    padding: '3% 9% ',
                    fontSize: 20,
                }}>
                {page == 'Home'
                    ? '查看記錄'
                    : page == 'FoodDetails'
                    ? '加入購買車'
                    : page == 'OrderSuccess'
                    ? '送出訂單'
                    : '加單'}
            </Link> */}
            <Button
                disabled={onSubmit}
                class="ui button"
                // href="/OrderSuccess"
                onClick={() => {
                    setOnSubmit(true);
                    handleSubmit();
                }}
                style={{
                    backgroundColor: '#016D49',
                    color: 'white',
                    padding: '3% 9% ',
                    fontSize: 20,
                }}>
                {onSubmit ? <Spin indicator={antIcon} /> : <></>}
                {page == 'Home'
                    ? '查看記錄'
                    : page == 'FoodDetails'
                    ? '加入購買車'
                    : page == 'OrderSuccess'
                    ? '加單'
                    : page == 'Cart'
                    ? '送出訂單'
                    : page == 'OrderHistory'
                    ? '加單'
                    : '加單'}
            </Button>
        </div>
    );
};

export default Footer1;
