import _ from 'lodash';
import React, { useState, useEffect } from "react";
import {
  Tabs,
  List,
  Skeleton,
  Col,
  Row,
} from "antd";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
let { path } = require('../../public/public.json');

const data2 = [
  {
    title: "台式炒飯 a",
    price: "$78",
  },
  {
    title: "台式炒飯 b",
    price: "$77",
  },
  {
    title: "台式炒飯 c",
    price: "$76",
  },
  {
    title: "台式炒飯 d",
    price: "$75",
  },
];
export const Cate = (cate = []) => {
  const navigate = useNavigate();
  const [cateJ, setCateJ] = useState([]);
  const [goods, setGoods] = useState([]);
  const [cateKey, setCateKey] = useState(0);
  const [filterGoods, setFilterGoods] = useState([]);
  useEffect(() => {
    getCate();
  }, [])
  useEffect(() => {
    getGoods();
  }, [cateJ])

  useEffect(() => {
    getGoodsInfo();
  }, [goods])

  useEffect(() => {
    goodsInfo();
  }, [filterGoods])

  const onChange = (key) => {
    let getGoods = _.filter(goods, { 'cateId': key });
    setCateKey(key)
    let arr = [];
    arr.push(goodsInfo(getGoods))
    return arr;
  };

  const getCate = async () => {
    await axios.get(`${path}/cate.php`).then(res => {
      let { data, status } = res;
      let arr = [];
      let json = {};
      _.forEach(data, function (value, key) {
        json = {
          label: value.name,
          key: value.id,
          children: (
            onChange(value.id)
          ),
        }
        arr.push(json);
      });
      setCateJ(arr);
      console.log(data[0].id)
    });
  }

  const items = cateJ.map((_, i) => {
    const id = String(i + 1);
    let { label, key } = _;
    return {
      label: `${label}`,
      key: id,
      children:  
      <div
      id="scrollableDiv"
      style={{
        height: "38vh",
        overflow: "auto",
        padding: "0 16px",
      }}
    >
      <InfiniteScroll
        dataLength={filterGoods.length}
        // next={loadMoreData}
        // hasMore={goods.length < 50}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        scrollableTarget="scrollableDiv"
      >
        <List
          itemLayout="horizontal"
          dataSource={filterGoods}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <img
                    src={require("../../assets/images/food1.png")}
                    alt="food"
                    style={{
                      height: "7vh",
                    }}
                  />
                }
                title={item.name}
                description={item.price}
              />
              <Button onClick={() => onclickGoods(item.id)} circular icon='add' style={{ float: "right", fontSize: 10, color: "#016D49", backgroundColor: "white", border: "rgb(1, 109, 73) 1px solid" }} />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>,
    };
  });

  const getGoodsInfo = () => {
    let getGoods = _.filter(goods, { 'cateId': "3" });
    setCateKey("3")
    setFilterGoods(getGoods)
  }

  const getGoods = async () => {
    await axios.get(`${path}/php/goods.php`).then(res => {
      let { data, status } = res;
      setGoods(data);
    });
  }

  const onclickGoods = (value) => {
    navigate(`/FoodDetails?good_id=${value}`);
    console.log(value);
    //href="/FoodDetails" 
  }
  const goodsInfo = () => {
    return (
      <>
        <div
          id="scrollableDiv"
          style={{
            height: "38vh",
            overflow: "auto",
            padding: "0 16px",
          }}
        >
          <InfiniteScroll
            dataLength={filterGoods.length}
            // next={loadMoreData}
            // hasMore={goods.length < 50}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              itemLayout="horizontal"
              dataSource={filterGoods}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={require("../../assets/images/food1.png")}
                        alt="food"
                        style={{
                          height: "7vh",
                        }}
                      />
                    }
                    title={item.name}
                    description={item.price}
                  />
                  <Button onClick={() => onclickGoods(item.id)} circular icon='add' style={{ float: "right", fontSize: 10, color: "#016D49", backgroundColor: "white", border: "rgb(1, 109, 73) 1px solid" }} />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>

      </>
    )
  };
  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col flex={2}>
          <h3 style={{ textAlign: "center" }}>系列</h3>
        </Col>
        <Col flex={4}>
          <h3 style={{ textAlign: "left", paddingLeft: 40 }}>今日推薦</h3>
        </Col>
      </Row>

      <Row style={{ paddingTop: 20 }}>
        <Col span={24}>
          <Tabs
            // defaultActiveKey={1}
            // onChange={onChange}
            tabPosition={"left"}
            items={items}
          />
        </Col>
      </Row>
    </>
  );
};
// export default Cate;
