import React from 'react';
// import { EditOutlined } from "@ant-design/icons";
import {Col, Row, Divider} from 'antd';
import {Header1} from '../../components/Header1';
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import axios from 'axios';
import _ from 'lodash';

import Cookies from 'js-cookie';
let {path} = require('../../public/public.json');

const OrderSuccess = () => {
    const FoodDetails = () => {
        const list = [];
        let foodDetails = JSON.parse(
            sessionStorage.getItem('bought_goods_Json')
        );
        console.log(foodDetails);
        if (_.isNull(foodDetails))
            foodDetails.forEach((value, index) => {
                list.push(
                    <>
                        <Col span={8}>
                            <h1 style={{fontWeight: 'bold', fontSize: 18}}>
                                {value.name}
                            </h1>
                            <p style={{fontSize: 12}}>{value.matchName}</p>
                            <p style={{fontSize: 12}}>{value.drinkName}</p>
                        </Col>
                        <Col
                            span={8}
                            style={{
                                fontWeight: 'bold',
                                fontSize: 20,
                                textAlign: 'right',
                            }}>
                            x 1
                        </Col>
                        <Col span={8}>
                            <h1
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    textAlign: 'right',
                                }}>
                                ${value.total}
                            </h1>
                        </Col>
                    </>
                );
            });
        return list;
    };

    // const onSubmitOrder = async () => {
    //     let foodJson = JSON.parse(sessionStorage.getItem('order_goods_Json'));
    //     let totalPrice = JSON.parse(sessionStorage.getItem('cart_goods_Json'));
    //     let getFoodJsonFromDB = [];
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const tableCode = atob(queryParams.get('tableCode'));
    //     const formData = new FormData();
    //     getFoodJsonFromDB.push(foodJson[0]);
    //     let user = JSON.parse(sessionStorage.getItem('loginStatus'));
    //     if (user) {
    //         formData.append('uid', user.uid);
    //     }
    //     formData.append('foodJson', JSON.stringify(foodJson));
    //     formData.append('total', totalPrice.total);
    //     formData.append('tableCode', tableCode);
    //     console.log(formData);
    //     // formData.append('', JSON.stringify(getFoodJsonFromDB));
    //     await axios.post(`${path}/addTableOrder.php`, formData).then(res => {
    //         console.log('Orderdatassss', res);
    //     });

    //     await axios.post(`${path}/getTableOrder.php`, formData).then(res => {
    //         let [data] = res.data;
    //         if (_.isUndefined(data)) return;
    //         _.forEach(JSON.parse(data.foodJson), function (value, index) {
    //             getFoodJsonFromDB.push(value);
    //         });
    //     });
    //     sessionStorage.removeItem('cart_goods_Json');
    //     sessionStorage.removeItem('order_goods_Json');
    // };

    return (
        <>
            <HeaderTop page={'OrderSuccess'} />
            {/* <Header1 /> */}
            <Row
                style={{
                    width: '100vw',
                    // height: "76vh",
                    padding: '1% 5%',
                    marginTop: 25,
                    // overflowY: "scroll",
                }}>
                <div
                    style={{
                        width: '90vw',
                        boxShadow: '1px 2px 9px #707070',
                        borderRadius: '2%',
                    }}>
                    <Row
                        style={{
                            width: '100%',
                            padding: '0 5%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            marginTop: '13%',
                        }}>
                        <Col span={8}>
                            <h4
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    color: '#016D49',
                                }}>
                                已選項目
                            </h4>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}>
                                <p
                                    style={{
                                        fontSize: 15,
                                        textAlign: 'right',
                                    }}>
                                    {atob(
                                        decodeURIComponent(
                                            Cookies.get('tableCode')
                                        )
                                    )}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Divider style={{width: '80vw'}} />
                    <div
                        style={{
                            width: '100%',
                            padding: '5%',
                            marginTop: '-5%',
                        }}>
                        <Row style={{padding: '0 2%'}}>
                            <FoodDetails />
                        </Row>
                        <Divider style={{width: '80vw'}} />
                    </div>
                </div>
                <Row
                    style={{padding: '2%', textAlign: 'center', width: '80vw'}}>
                    <Col span={24}>
                        <p
                            style={{
                                fontSize: 14,
                                color: '#B4B4B4',
                                padding: '3%',
                            }}>
                            最後更新時間 15:22:56
                        </p>
                    </Col>
                </Row>
            </Row>

            <Footer1 page={'OrderSuccess'} />
        </>
    );
};

export default OrderSuccess;
