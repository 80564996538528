import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import "semantic-ui-css/semantic.min.css";

export const Header1 = () => {
  return (
    <a href="/">
      <div
        style={{
          paddingTop: 12,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "5% 7%",
          position: "fixed",
          top: 0,
          width: "100%",
          background: "#ffff",
          color: "black",
          fontWeight: "bold",
          zIndex: "9",
        }}
      >
        <LeftOutlined />
        <p style={{ fontSize: 16 }}>返回主菜單</p>
      </div>
    </a>
  );
};
export default Header1;
