import React, {useEffect, useState} from 'react';
// import { EditOutlined } from "@ant-design/icons";
import {Button, Col, Row, Divider, Modal} from 'antd';
import {Header1} from '../../components/Header1';
import {Footer1} from '../../components/Footer1';
import {HeaderTop} from '../../components/HeaderTop';
import axios from 'axios';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

import Cookies from 'js-cookie';
let {path, host} = require('../../public/public.json');

const Cart = () => {
    const navigate = useNavigate();
    const [onEdit, setOnEdit] = useState(false);
    const [total, setTotal] = useState(false);
    const [cart, setCart] = useState(
        JSON.parse(sessionStorage.getItem('cart_goods_Json'))
    );

    const [modal, contextHolder] = Modal.useModal();
    const FoodDetails = () => {
        const list = [];
        if (!_.isNull(cart))
            cart.forEach((value, index) => {
                list.push(
                    <>
                        <Col span={onEdit ? 2 : 0}>
                            <img
                                onClick={() => {
                                    removeItem(index);
                                }}
                                width={20}
                                src={require('../../assets/icons/delete.png')}
                            />
                        </Col>
                        <Col span={13}>
                            <h1
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    textAlign: 'left',
                                    marginTop: 2.5,
                                    marginBottom: 2.5,
                                }}>
                                {value.name}
                            </h1>
                            <p style={{fontSize: 12, textAlign: 'left'}}>
                                {value.matchName}
                            </p>
                            <p style={{fontSize: 12, textAlign: 'left'}}>
                                {value.drinkName}
                            </p>
                        </Col>
                        <Col
                            span={4}
                            style={{
                                fontWeight: 'bold',
                                fontSize: 16,
                                textAlign: 'right',
                                marginTop: 5,
                                marginBottom: 2.5,
                            }}>
                            x 1
                        </Col>
                        <Col span={onEdit ? 5 : 7}>
                            <h1
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    textAlign: 'right',
                                    marginTop: 5,
                                    marginBottom: 2.5,
                                }}>
                                ${value.total}
                            </h1>
                        </Col>
                    </>
                );
            });
        return list;
    };

    function removeItem(index) {
        let items = JSON.parse(sessionStorage.getItem('cart_goods_Json'));
        let item = items.splice(index, 1); // remove 1 item at given index
        sessionStorage.setItem('cart_goods_Json', JSON.stringify(items));
        setCart(items);
        items = JSON.parse(sessionStorage.getItem('order_goods_Json'));
        const newArr = items.filter(i => {
            // console.log('item', items.add_time);
            return i.add_time !== item[0].add_time;
        });
        console.log(newArr);

        // items.splice(index, 1); // remove 1 item at given index
        sessionStorage.setItem('order_goods_Json', JSON.stringify(newArr));
    }

    useEffect(() => {
        console.log(cart);
        let count = 0;
        if (cart)
            if (cart.length > 0)
                cart.forEach(item => {
                    count += item.total;
                });
        setTotal(count);
    }, [cart]);

    const onSubmitOrder = async () => {
        let foodJson = JSON.parse(sessionStorage.getItem('order_goods_Json'));
        let totalPrice = 0;
        JSON.parse(sessionStorage.getItem('cart_goods_Json')).forEach(item => {
            totalPrice += item.total;
        });
        let getFoodJsonFromDB = [];
        const queryParams = new URLSearchParams(window.location.search);
        const tableCode = atob(decodeURIComponent(Cookies.get('tableCode')));
        const orderId = Cookies.get('orderId');
        const formData = new FormData();
        getFoodJsonFromDB.push(foodJson[0]);
        let user = JSON.parse(sessionStorage.getItem('loginStatus'));
        if (user) {
            formData.append('uid', user.uid);
        }
        formData.append('foodJson', JSON.stringify(foodJson));
        formData.append('total', totalPrice);
        formData.append('tableCode', tableCode);
        formData.append('orderId', orderId);
        formData.append('shopId', 1);

        // formData.append('', JSON.stringify(getFoodJsonFromDB));

        await axios
            .post(`${path}/addTableOrder.php`, formData)
            .then(async re => {
                await axios
                    .post(`${path}/getTableOrder.php`, formData)
                    .then(res => {
                        let [data] = res.data;
                        if (_.isUndefined(data)) return;
                        _.forEach(
                            JSON.parse(data.foodJson),
                            function (value, index) {
                                getFoodJsonFromDB.push(value);
                            }
                        );
                    });

                await axios
                    .post(`${host}/Printer/OrderList.html`, formData)
                    .then(async res => {
                        await axios
                            .post(`${host}/Printer/Vegetable.html`, formData)
                            .then(res => {
                                sessionStorage.removeItem('order_goods_Json');
                                sessionStorage.removeItem('cart_goods_Json');

                                let secondsToGo = 3;
                                let instance = modal.info({
                                    title: '訂單已提交',
                                    content: `${secondsToGo}後自動關閉.`,
                                    onOk() {
                                        navigate(`/OrderHistory`);
                                    },
                                });

                                const timer = setInterval(() => {
                                    secondsToGo -= 1;
                                    instance.update({
                                        content: `${secondsToGo}後自動關閉.`,
                                    });
                                }, 1000);

                                setTimeout(() => {
                                    clearInterval(timer);
                                    instance.destroy();
                                    navigate(`/OrderHistory`);
                                }, secondsToGo * 1000);
                            });
                    });
            });
    };
    return (
        <>
            <HeaderTop page={'Cart'} />
            {/* <Header1 /> */}
            <Row
                style={{
                    width: '100vw',
                    height: '76vh',
                    padding: '1% 5%',
                    marginTop: 25,
                    overflowY: 'scroll',
                }}>
                <div
                    style={{
                        width: '90vw',
                        boxShadow: '1px 2px 9px #707070',
                        borderRadius: '2%',
                    }}>
                    <Row
                        style={{
                            width: '100%',
                            padding: '0 5%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            marginTop: '13%',
                        }}>
                        {cart ? (
                            cart.length != 0 ? (
                                <>
                                    <Col span={8}>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                color: '#016D49',
                                            }}>
                                            已選項目
                                        </h4>
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={8}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                setOnEdit(state => !state);
                                            }}>
                                            <img
                                                width={20}
                                                src={require('../../assets/icons/edit.png')}
                                            />
                                            <p
                                                style={{
                                                    fontSize: 15,
                                                    textAlign: 'right',
                                                }}>
                                                更改
                                            </p>
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                    </Row>
                    <Divider style={{width: '80vw'}} />
                    <div
                        style={{
                            width: '100%',
                            padding: '5%',
                            marginTop: '-5%',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                        <Row
                            style={{
                                padding: '0 2%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            {cart ? (
                                cart.length != 0 ? (
                                    <FoodDetails />
                                ) : (
                                    <h3 style={{textAlign: 'center'}}>
                                        還沒有東西，快去下單吧！
                                    </h3>
                                )
                            ) : (
                                <h3
                                    style={{
                                        textAlign: 'center',
                                    }}>
                                    還沒有東西，快去下單吧！
                                </h3>
                            )}
                        </Row>
                        {cart ? (
                            <>
                                <Divider style={{width: '80vw'}} />
                                <Row
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'nowrap',
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        marginTop: '3%',
                                        marginBottom: '3%',
                                        padding: '0px 2%',
                                    }}>
                                    <Col span={8}>
                                        <h4
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: '500',
                                                textAlign: 'left',
                                            }}>
                                            小計
                                        </h4>
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={8}>
                                        <h4
                                            style={{
                                                fontWeight: '500',
                                                textAlign: 'right',
                                            }}>
                                            ${total}
                                        </h4>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'nowrap',
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        marginTop: '3%',
                                        marginBottom: '3%',
                                        padding: '0px 2%',
                                    }}>
                                    <Col span={8}>
                                        <h3
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: '900',
                                                fontSize: 20,
                                                textAlign: 'left',
                                            }}>
                                            總計
                                        </h3>
                                    </Col>
                                    <Col span={8}></Col>
                                    <Col span={8}>
                                        <h3
                                            style={{
                                                fontWeight: 'bold',
                                                textAlign: 'right',
                                            }}>
                                            ${total}
                                        </h3>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <Row
                    style={{padding: '2%', textAlign: 'center', width: '80vw'}}>
                    <Col span={24}>
                        {cart ? (
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#B4B4B4',
                                    padding: '3%',
                                }}>
                                最後更新時間{' '}
                                {moment(cart[cart.length - 1].add_time).format(
                                    'HH:mm:ss'
                                )}
                            </p>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </Row>
            {contextHolder}
            {cart ? (
                cart.length != 0 ? (
                    <Footer1 page={'Cart'} submit={() => onSubmitOrder()} />
                ) : (
                    <Footer1 page={'noItem'} submit={() => onSubmitOrder()} />
                )
            ) : (
                <Footer1 page={'noItem'} submit={() => onSubmitOrder()} />
            )}
        </>
    );
};

export default Cart;
