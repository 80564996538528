import React, { useEffect } from "react";
// import { LeftOutlined } from "@ant-design/icons";
import { Col, Row, Checkbox, Divider, List, Typography, Descriptions, Card, Space, Button, Image, Avatar } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
// import { ShoppingCartOutlined } from "@ant-design/icons";
// import { Home } from "../Home";
import { Header1 } from "../../components/Header1";
import { Footer1 } from "../../components/Footer1";
import { HeaderTop } from "../../components/HeaderTop";
import { useNavigate } from 'react-router-dom';
let { path } = require('../../public/public.json');

const Account = () => {
  const navigate = useNavigate();
  useEffect(() => {
  }, [])
  
  const logout = () => {
    sessionStorage.setItem("loginStatus", null);
    navigate("/login");
  }

  const accountInfo = () => {
    navigate("/AccountInfo");
  }

  const Coupon = () => {
    navigate("/Coupon");
  }


  const Home = () => {
    navigate("/");
  }

  const BookingRecord = () => {
    navigate("/BookingRecord");
  }

  return (
    <>
      <HeaderTop page={"Account"} />

      <Row style={{ margin: 15 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
            }}
          >
            <Row style={{ margin: -10 }}>
              <Col span={12}>
                <Avatar size={120} src={require('../../assets/icons/account-icon.png')} />
              </Col>
              <Col span={12}>
                <Row style={{marginTop: 30}}>
                <Col span={24} style={{fontFamily: "Segoe UI, Regular", fontSize: 16}}>會員名稱</Col>
                <Col span={24} style={{fontFamily: "Segoe UI, Regular", fontSize: 16, paddingTop: 10, color: "#6A6A6A"}}>積分 <span style={{color: 'green'}}>$</span></Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ margin: 15 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
            }}
          >
            <Card.Grid
              style={{
                width: '100%',
                borderRadius: 10,
              }}>
              <Row style={{ margin: -10, }} onClick={() => accountInfo()}>
                <Col span={3}>
                  <Image
                    width={35}
                    src={require('../../assets/icons/account-member.png')}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: 6 }}>
                  <span>帳號詳情</span>
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid
              style={{
                width: '100%',
              }}>
              <Row style={{ margin: -10, }} onClick={() => BookingRecord()}>
                <Col span={3}>
                  <Image
                    width={35}
                    src={require('../../assets/icons/account-records.png')}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: 6 }}>
                  <span>訂單</span>
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid
              style={{
                width: '100%',
                borderRadius: 10,
              }}>
              <Row style={{ margin: -10, }} onClick={() => Coupon()}>
                <Col span={3}>
                  <Image
                    width={35}
                    src={require('../../assets/icons/account-coupon.png')}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: 6 }}>
                  <span>禮券</span>
                </Col>
              </Row>
            </Card.Grid>

            <Card.Grid
              style={{
                width: '100%',
              }}>
              <Row style={{ margin: -10, }} onClick={() => logout()}>
                <Col span={3}>
                  <Image
                    width={35}
                    src={require('../../assets/icons/account-logout.png')}
                  />
                </Col>
                <Col span={12} style={{ paddingTop: 6 }}>
                  <span>登出</span>
                </Col>
              </Row>
            </Card.Grid>
          </Card>
        </Col>
      </Row>

      <Row style={{ margin: 15 }}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
            }}
          >
            <Row >
              <Col span={24}>
                <Row justify="center" align="middle">
                  <Button type="primary" style={{ backgroundColor: "#016D49", width: '50%' }} size={"large"} onClick={() => Home()}>
                    <span style={{ color: "#FFFFFF" }}>開始點餐</span>
                  </Button>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Account;
